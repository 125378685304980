import Action from "content/recipientDetails/duck/actions/types/recipientDetailsActions";
import RecipientDetailsActionType from "content/recipientDetails/duck/actionTypes/recipientDetailsActionType";
import State from "content/recipientDetails/duck/interfaces/IRecipientDetailsState";

const defaultState: State = {
    isLoading: true,
    errorMessage: "",
    requirements: null,
};

const recipientDetailsReducer = (state: State = defaultState, action: Action): State => {
    switch (action.type) {
        case RecipientDetailsActionType.RECIPIENT_DETAILS_SHOW_SHIMMER:
            return {
                ...state,
                isLoading: true,
            };

        case RecipientDetailsActionType.RECIPIENT_DETAILS_LOAD_DATA:
            return {
                ...state,
                isLoading: false,
                requirements: action.payload.requirements,
            };

        case RecipientDetailsActionType.RECIPIENT_DETAILS_DISPLAY_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload.errorMessage,
            };

        default:
            return state;
    }
};

export default recipientDetailsReducer;
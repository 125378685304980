import { ContextualMenu, Dialog, DialogFooter, DialogType, Spinner, Stack } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import React from "react";

import closeDialogBox from "chrome/dialogBox/duck/actions/closeDialogBox";
import IAppState from "duck/interfaces/IAppState";
import showErrorInDialogBox from "chrome/dialogBox/duck/actions/showErrorInDialogBox";

import styles from "chrome/dialogBox/dialogBox.styles";

export default function DialogBox(): JSX.Element {
    const dispatch = useDispatch();
    const { isDialogBoxVisible, dialogBoxData, isShowSpinnerInDialogBox, dialogBoxErrorMessage } =
        useSelector((state: IAppState) => state.chromeState.dialogBoxState);

    let dialogBoxTitleText = "";
    let dialogBoxBodyRender: (() => React.ReactNode) | undefined;
    let dialogBoxFooterRender: (() => React.ReactNode) | undefined;

    if (dialogBoxData) {
        dialogBoxTitleText = dialogBoxData.dialogBoxTitleText;
        dialogBoxBodyRender = dialogBoxData.dialogBoxBodyRender;
        dialogBoxFooterRender = dialogBoxData.dialogBoxFooterRender;
    }

    const closeDialog = React.useCallback((): void => {
        if (isDialogBoxVisible) {
            dispatch(closeDialogBox());
        }

        if (dialogBoxErrorMessage) {
            dispatch(showErrorInDialogBox({ errorMessage: "" }));
        }
    }, [dispatch, isDialogBoxVisible, dialogBoxErrorMessage]);

    return (
        <Dialog
            styles={styles.dialog}
            hidden={!isDialogBoxVisible}
            onDismiss={closeDialog}
            dialogContentProps={{
                type: DialogType.normal,
                title: dialogBoxTitleText,
                showCloseButton: true,
                styles: styles.dialogContent,
            }}
            modalProps={{
                isBlocking: true,
                dragOptions: {
                    moveMenuItemText: "Move",
                    closeMenuItemText: "Close",
                    menu: ContextualMenu
                },
            }}
        >
            {
                // Rendering dialog box body.
                dialogBoxBodyRender && dialogBoxBodyRender()
            }
            {
                // Rendering error message.
                <div className={styles.errorMessage}>
                    {dialogBoxErrorMessage && `Error: ${dialogBoxErrorMessage}`}
                </div>
            }

            <DialogFooter styles={styles.footer}>
                {dialogBoxFooterRender && dialogBoxFooterRender()}
            </DialogFooter>

            {
                isShowSpinnerInDialogBox &&
                <Stack styles={styles.spinnerStack} horizontalAlign="center" verticalAlign="center">
                    <Spinner />
                </Stack>
            }
        </Dialog>
    );
}

import { Reducer } from "redux";

import IRegistrationPersonalContentState from "content/registration/personal/duck/interfaces/IRegistrationPersonalContentState";
import IRegistrationPersonalSelectPivotAction from "content/registration/personal/duck/actions/interfaces/IRegistrationPersonalSelectPivotAction";
import RegistrationPersonalActionType from "content/registration/personal/duck/actionTypes/registrationPersonalActionType";
import RegistrationPersonalPivots from "content/registration/personal/enums/registrationPersonalPivots";

type Action = IRegistrationPersonalSelectPivotAction;
type State = IRegistrationPersonalContentState;

const defaultState: State = {
    pivot: RegistrationPersonalPivots.AboutYou,
};

const registrationPersonalContentReducer: Reducer<State, Action> =
    (state: State = defaultState, action: Action): State => {
        switch (action.type) {
            case RegistrationPersonalActionType.REGISTRATION_PERSONAL_SELECT_PIVOT:
                return {
                    ...state,
                    pivot: action.payload.pivot,
                };

            default:
                return state;
        }
    };

export default registrationPersonalContentReducer;
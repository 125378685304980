import { combineReducers, Reducer } from "redux";

import choiceGroupFieldControlReducer from "common/fields/choiceGroupField/duck/choiceGroupFieldControlReducer";
import ContentState from "content/recipients/panelRecipientInfo/duck/interfaces/IRecipientsRecipientInfoContentState";
import dropdownFieldControlReducer from "common/fields/dropdownField/duck/dropdownFieldControlReducer";
import FieldNames from "content/recipients/panelRecipientInfo/enums/recipientsRecipientInfoFormFieldNames";
import FormState from "content/recipients/panelRecipientInfo/duck/interfaces/IRecipientsRecipientInfoFormState";
import IRecipientsRecipientInfoLoadCountriesAction from "content/recipients/panelRecipientInfo/duck/actions/interfaces/IRecipientsRecipientInfoLoadCountriesAction";
import IRecipientsRecipientInfoLoadRequirementsAction from "content/recipients/panelRecipientInfo/duck/actions/interfaces/IRecipientsRecipientInfoLoadRequirementsAction";
import IRecipientsRecipientInfoSaveLastPostedRecipientIDAction from "content/recipients/panelRecipientInfo/duck/actions/interfaces/IRecipientsRecipientInfoSaveLastPostedRecipientIDAction";
import RecipientsRecipientInfoActionType from "content/recipients/panelRecipientInfo/duck/actionTypes/recipientsRecipientInfoActionType";
import State from "content/recipients/panelRecipientInfo/duck/interfaces/IRecipientsRecipientInfoState";
import textFieldControlReducer from "common/fields/textField/duck/textFieldControlReducer";

type Action = IRecipientsRecipientInfoLoadCountriesAction |
    IRecipientsRecipientInfoLoadRequirementsAction |
    IRecipientsRecipientInfoSaveLastPostedRecipientIDAction;

const defaultState: ContentState = {
    countries: [],
    requirements: null,
};

const contentReducer: Reducer<ContentState, Action> = (state = defaultState, action: Action): ContentState => {
    switch (action.type) {
        case RecipientsRecipientInfoActionType.RECIPIENTS_RECIPIENT_INFO_LOAD_COUNTRIES:
            return {
                ...state,
                countries: action.payload.countries,
            };

        case RecipientsRecipientInfoActionType.RECIPIENTS_RECIPIENT_INFO_LOAD_REQUIREMENTS:
            return {
                ...state,
                requirements: action.payload.requirements,
            };

        case RecipientsRecipientInfoActionType.RECIPIENTS_RECIPIENT_INFO_SAVE_LAST_POSTED_RECIPIENT_ID:
            return {
                ...state,
                lastPostedRecipientID: action.payload.id,
            };

        default:
            return state;
    }
};

const formReducer = combineReducers<FormState>({
    typeChoiceGroupFieldFieldState: choiceGroupFieldControlReducer(FieldNames.type),
    countryDropdownFieldFieldState: dropdownFieldControlReducer(FieldNames.country),
    recipientTextFieldState: textFieldControlReducer(FieldNames.recipient),
    contactTextFieldState: textFieldControlReducer(FieldNames.contact),
    emailTextFieldState: textFieldControlReducer(FieldNames.email),
    referenceNumberTextFieldState: textFieldControlReducer(FieldNames.referenceNumber),
});

const recipientsRecipientInfoReducer = combineReducers<State>({
    contentState: contentReducer,
    formState: formReducer,
});

export default recipientsRecipientInfoReducer;
import { combineReducers } from "redux";

import IRegistrationPersonalState from "content/registration/personal/duck/interfaces/IRegistrationPersonalState";
import registrationPersonalAboutYouReducer from "content/registration/personal/aboutYou/duck/registrationPersonalAboutYouReducer";
import registrationPersonalContentReducer from "content/registration/personal/duck/registrationPersonalContentReducer";
import registrationPersonalVerificationBerbixFlowReducer from "content/registration/personal/verification/berbixFlow/duck/registrationPersonalVerificationBerbixFlowReducer";

const registrationPersonalReducer = combineReducers<IRegistrationPersonalState>({
    contentState: registrationPersonalContentReducer,
    aboutYouState: registrationPersonalAboutYouReducer,
    verificationState: registrationPersonalVerificationBerbixFlowReducer,
});

export default registrationPersonalReducer;
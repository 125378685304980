import { CommandButton } from "@fluentui/react";
import { useMsal } from "@azure/msal-react";
import React from "react";

import { changePasswordRequest, loginRequest } from "auth/authConfig";
import Auth from "auth/auth";
import IAuthIdTokenClaims from "auth/interfaces/IAuthIdTokenClaims";

import calloutStyles from "common/style/callout.styles";
import ecbMenuStyles from "common/style/ecbMenuItem.styles";
import userButtonStyles from "common/components/userButton/userButton.styles";

export default function UserButton(): JSX.Element {
    const { accounts } = useMsal();
    const { name, username, idTokenClaims } = accounts[0];
    const displayName = name || username || (idTokenClaims as IAuthIdTokenClaims).email;

    const handleChangePassword = React.useCallback(() => {
        void Auth.instance.loginRedirect(changePasswordRequest);
    }, []);

    const handleSignout = React.useCallback(() => {
        void Auth.instance.logoutRedirect(loginRequest);
    }, []);

    const menuItems = React.useMemo(() => [
        {
            key: "changePassword",
            text: "Change Password",
            iconProps: { iconName: "Lock" },
            itemProps: { styles: ecbMenuStyles.menuItem },
            onClick: handleChangePassword,
        },
        {
            key: "logout",
            text: "Logout",
            iconProps: { iconName: "PowerButton" },
            itemProps: { styles: ecbMenuStyles.menuItem },
            onClick: handleSignout,
        },
    ], [handleChangePassword, handleSignout]);

    return (
        <CommandButton
            text={displayName}
            iconProps={{ iconName: "Contact" }}
            styles={userButtonStyles}
            menuProps={{
                styles: ecbMenuStyles.menu,
                calloutProps: { styles: calloutStyles },
                items: menuItems,
            }}
        />
    );
}
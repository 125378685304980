import fundIngAccountInstantVerificationActionType from "content/common/fundingAccount/paneInstantVerificationWrapper/duck/actionTypes/fundIngAccountInstantVerificationActionType";
import IFundingAccountInstantVerificationLoadTokenAction from "content/common/fundingAccount/paneInstantVerificationWrapper/duck/actions/interfaces/IFundingAccountInstantVerificationLoadTokenAction";
import IFundingAccountInstantVerificationState from "content/common/fundingAccount/paneInstantVerificationWrapper/duck/interfaces/IFundingAccountInstantVerificationState";

type Action = IFundingAccountInstantVerificationLoadTokenAction;
type State = IFundingAccountInstantVerificationState;

const defaultState: State = {
    token: null
};

function fundingAccountInstantVerificationReducer(
    state: State = defaultState,
    action: Action
): State {
    switch (action.type) {
        case fundIngAccountInstantVerificationActionType.FUNDING_ACCOUNT_INSTANT_VERIFICATION_LOAD_TOKEN:
            return {
                token: action.payload.token
            };

        default:
            return state;
    }
}

export default fundingAccountInstantVerificationReducer;
import ContentActions from "content/duck/actions/types/contentActions";
import ContentActionType from "content/duck/actionTypes/contentActionType";
import State from "content/duck/interfaces/IContentState";

const defaultState: State = {
    isLoading: false,
    errorMessage: "",
    accountStatus: null,
};

const contentReducer = (state = defaultState, action: ContentActions): State => {
    switch (action.type) {
        case ContentActionType.CONTENT_REQUEST_DATA:
            return {
                ...state,
                isLoading: true,
            };

        case ContentActionType.CONTENT_LOAD_DATA:
            return {
                ...state,
                isLoading: false,
                accountStatus: action.payload.accountStatus,
            };

        case ContentActionType.CONTENT_DISPLAY_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload.errorMessage,
            };

        default:
            return state;
    }
};

export default contentReducer;

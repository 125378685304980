import { CommandBar, Panel as FluentUIPanel, IPanelProps, Spinner, Stack } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import React from "react";

import closePanel from "chrome/panel/duck/actions/closePanel";
import displayErrorInPanel from "chrome/panel/duck/actions/displayErrorInPanel";
import ErrorMessageBar from "common/components/errorMessageBar/errorMessageBar";
import hideSpinnerInPanel from "chrome/panel/duck/actions/hideSpinnerInPanel";
import IAppState from "duck/interfaces/IAppState";

import styles from "chrome/panel/panel.styles";

export default function Panel(): JSX.Element {
    const dispatch = useDispatch();
    const { isVisible, shouldShowSpinner, data, errorMessage } =
        useSelector((state: IAppState) => state.chromeState.panelState);

    const clearErrorMessage = React.useCallback(() => {
        if (errorMessage) {
            dispatch(displayErrorInPanel({ errorMessage: "" }));
        }
    }, [dispatch, errorMessage]);

    const onPanelDismiss = React.useCallback(() => {
        if (isVisible) {
            dispatch(closePanel());
        }

        clearErrorMessage();

        if (shouldShowSpinner) {
            dispatch(hideSpinnerInPanel());
        }
    }, [dispatch, clearErrorMessage, shouldShowSpinner, isVisible]);

    const { commands } = data;

    React.useEffect((): void => {
        if (!isVisible) {
            onPanelDismiss();
        }
    }, [isVisible, onPanelDismiss]);

    const onRenderNavigationContent = React.useCallback(
        (props?: IPanelProps, defaultRender?: (props?: IPanelProps) => JSX.Element | null): JSX.Element => (
            <>
                <Stack.Item>
                    <Stack horizontal horizontalAlign="end">
                        {commands &&
                            <div className="fullWidth">
                                <CommandBar styles={styles.commandBar} items={commands} />
                            </div>
                        }
                        {defaultRender && defaultRender(props)}
                    </Stack>
                </Stack.Item>
                {errorMessage &&
                    <Stack.Item tokens={styles.messageBarStackItemTokens(data.headerText ? true : false)}>
                        <ErrorMessageBar onDismiss={clearErrorMessage}>
                            {errorMessage}
                        </ErrorMessageBar>
                    </Stack.Item>
                }
            </>
        ), [commands, errorMessage, clearErrorMessage, data.headerText]);

    return (
        <FluentUIPanel
            isOpen={isVisible}
            styles={styles.panel}
            onRenderNavigationContent={onRenderNavigationContent}
            headerText={data.headerText}
            type={data.panelType}
            customWidth={data.customPanelWidth}
            onDismiss={onPanelDismiss}
            onRenderFooterContent={data.onRenderFooterContent}
            isFooterAtBottom
            // Note: Panel is closing when we click on dialogbox. As a work around
            //       not performing anything `onOuterClick`. 
            onOuterClick={(): void => undefined}
        >
            {shouldShowSpinner &&
                <Stack styles={styles.spinnerStack} verticalFill verticalAlign="center">
                    <Spinner />
                </Stack>
            }
            {data.content}
        </FluentUIPanel>
    );
}
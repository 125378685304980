import { Reducer } from "redux";

import IPayablesNewPayableSelectCardAction from "content/payables/payablesNewPayable/duck/actions/interfaces/IPayablesNewPayableSelectCardAction";
import IPayablesNewPayableSelectStepAction from "content/payables/payablesNewPayable/duck/actions/interfaces/IPayablesNewPayableSelectStepAction";
import PayablesNewPayableActionType from "content/payables/payablesNewPayable/duck/actionTypes/payablesNewPayableActionType";
import PayablesNewPayableWizardSteps from "content/payables/payablesNewPayable/enums/payablesNewPayableWizardSteps";
import State from "content/payables/payablesNewPayable/duck/interfaces/IPayablesNewPayableContentState";

type Action = IPayablesNewPayableSelectStepAction | IPayablesNewPayableSelectCardAction;

const defaultState: State = {
    selectedStep: PayablesNewPayableWizardSteps.PayableDetails,
    selectedCard: undefined,
};

const payablesNewPayableContentReducer: Reducer<State, Action> =
    (state: State = defaultState, action: Action): State => {
        switch (action.type) {
            case PayablesNewPayableActionType.PAYABLES_NEW_PAYABLE_SELECT_STEP:
                return {
                    ...state,
                    selectedStep: action.payload.step,
                };

            case PayablesNewPayableActionType.PAYABLES_NEW_PAYABLE_SELECT_CARD:
                return {
                    ...state,
                    selectedCard: action.payload.card,
                };

            default:
                return state;
        }
    };

export default payablesNewPayableContentReducer;
import Action from "content/payables/payablesNewPayable/selectRecipient/duck/actions/types/payablesNewPayableSelectRecipientActions";
import PayablesNewPayableSelectRecipientActionType from "content/payables/payablesNewPayable/selectRecipient/duck/actionTypes/payablesNewPayableSelectRecipientActionType";
import State from "content/payables/payablesNewPayable/selectRecipient/duck/interfaces/IPayablesNewPayableSelectRecipientState";

const defaultState: State = {
    isLoading: false,
    isFetching: false,
    errorMessage: "",
    recipients: [],
};

export default function payablesNewPayableSelectRecipientReducer(state = defaultState, action: Action): State {
    switch (action.type) {
        case PayablesNewPayableSelectRecipientActionType.PAYABLES_NEW_PAYABLE_SELECT_RECIPIENT_REQUEST_DATA:
            return {
                ...state,
                isLoading: true,
                errorMessage: "",
            };

        case PayablesNewPayableSelectRecipientActionType.PAYABLES_NEW_PAYABLE_SELECT_RECIPIENT_LOAD_DATA:
            return {
                ...state,
                isLoading: false,
                recipients: action.payload.recipients,
            };

        case PayablesNewPayableSelectRecipientActionType.PAYABLES_NEW_PAYABLE_SELECT_RECIPIENT_FETCH_MORE_DATA:
            return {
                ...state,
                isFetching: true,
                errorMessage: "",
            };

        case PayablesNewPayableSelectRecipientActionType.PAYABLES_NEW_PAYABLE_SELECT_RECIPIENT_APPEND_MORE_DATA:
            return {
                ...state,
                // On initial request for list if the result is an empty array,
                // it will call the append action and if at that time the isLoading will be true.
                // By making it false here we can hide the loading spinner and can show message
                // in component.
                isLoading: false,
                isFetching: false,
                // The filter() is used to remove the previous null item in the array before
                // appending the latest results to the array.
                recipients: state.recipients
                    ? state.recipients.concat(action.payload.recipients)
                    : state.recipients,
            };

        case PayablesNewPayableSelectRecipientActionType.PAYABLES_NEW_PAYABLE_SELECT_RECIPIENT_DISPLAY_ERROR:
            return {
                ...state,
                isLoading: false,
                isFetching: false,
                errorMessage: action.payload.errorMessage,
            };

        default:
            return state;
    }
}
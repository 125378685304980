import { MessageBar, MessageBarType } from "@fluentui/react";
import React from "react";

import errorMessageBarStyles from "common/components/errorMessageBar/errorMessageBar.styles";
import IErrorMessageBarProps from "common/components/errorMessageBar/interfaces/IErrorMessageBarProps";

export default function ErrorMessageBar(props: React.PropsWithChildren<IErrorMessageBarProps>): JSX.Element | null {
    if (!props.children) {
        return null;
    }

    return (
        <MessageBar
            messageBarType={MessageBarType.error}
            styles={errorMessageBarStyles(props.applyMarginAtBottom)}
            {...props}
        />
    );
}
import { combineReducers } from "redux";

import dropdownFieldControlReducer from "common/fields/dropdownField/duck/dropdownFieldControlReducer";
import FundingAccountAddCreditCardFormFieldNames from "content/common/fundingAccount/panelAddCreditCard/enum/fundingAccountAddCreditCardFormFieldnames";
import IFundingAccountAddCreditCardState from "content/common/fundingAccount/panelAddCreditCard/duck/interfaces/IFundingAccountAddCreditCardState";
import textFieldControlReducer from "common/fields/textField/duck/textFieldControlReducer";

const fundingAccountAddCreditCardReducer = combineReducers<IFundingAccountAddCreditCardState>({
    nameOnCardTextFieldState:
        textFieldControlReducer(FundingAccountAddCreditCardFormFieldNames.nameOnCard),
    creditCardNumberTextFieldState:
        textFieldControlReducer(FundingAccountAddCreditCardFormFieldNames.creditCardNumber),
    expirationDateTextFieldState:
        textFieldControlReducer(FundingAccountAddCreditCardFormFieldNames.expirationDate),
    cvcTextFieldState:
        textFieldControlReducer(FundingAccountAddCreditCardFormFieldNames.cvc),
    countryDropdownFieldState:
        dropdownFieldControlReducer(FundingAccountAddCreditCardFormFieldNames.country),
    postalCodeTextFieldState:
        textFieldControlReducer(FundingAccountAddCreditCardFormFieldNames.postalCode),
});

export default fundingAccountAddCreditCardReducer;
import { combineReducers } from "redux";

import autocompleteAddressReducer from "common/components/autocompleteAddress/duck/autocompleteAddressReducer";
import Entities from "content/common/enums/entities";
import IRecipientsState from "content/recipients/duck/interfaces/IRecipientsState";
import listPageReducer from "common/components/listPage/duck/listPageReducer";
import recipientsRecipientInfoReducer from "content/recipients/panelRecipientInfo/duck/recipientsRecipientInfoReducer";
import recipientsWizardReducer from "content/recipients/panelWizard/duck/recipientsWizardReducer";

const recipientsReducer = combineReducers<IRecipientsState>({
    contentState: listPageReducer(Entities.RECIPIENTS),
    wizardState: recipientsWizardReducer,
    recipientInfoState: recipientsRecipientInfoReducer,
    addressState: autocompleteAddressReducer(Entities.RECIPIENTS),
});

export default recipientsReducer;
import { Reducer } from "redux";

import CheckboxFieldActionType from "common/fields/checkboxField/duck/actionTypes/checkboxFieldActionType";
import IDisplayFieldErrorAction from "common/fields/common/duck/actions/interfaces/IDisplayFieldErrorAction";
import IFieldControlState from "common/fields/common/interfaces/IFieldControlState";
import IResetFieldAction from "common/fields/common/duck/actions/interfaces/IResetFieldAction";
import IUpdateFieldValueAction from "common/fields/common/duck/actions/interfaces/IUpdateFieldValueAction";

type Action = IDisplayFieldErrorAction | IUpdateFieldValueAction | IResetFieldAction;

type CreateCheckboxFieldControlReducer = (fieldName: string) => Reducer<IFieldControlState, Action>;

const defaultState: IFieldControlState = {
    value: "false",
    errorMessage: "",
};

const checkboxFieldControlReducer: CreateCheckboxFieldControlReducer =
    (fieldName: string): Reducer<IFieldControlState, Action> =>
        (state: IFieldControlState = defaultState, action: Action): IFieldControlState => {
            switch (action.type) {
                // Case when the server request fails. This should enable the checkbox field and display
                // the error message from the action's payload. Additionally, the visual value should be
                // reverted back to before the server request.
                // TODO: Confirm that this error case works with the server API such that the visual value and
                //       data source value does not change when an error occurs.
                case `${fieldName.toUpperCase()}_${CheckboxFieldActionType.DISPLAY_CHECKBOX_FIELD_ERROR}`:
                    const displayFieldErrorAction: IDisplayFieldErrorAction = action as IDisplayFieldErrorAction;

                    return {
                        ...state,
                        errorMessage: displayFieldErrorAction.payload.errorMessage,
                    };

                case `${fieldName.toUpperCase()}_${CheckboxFieldActionType.UPDATE_CHECKBOX_FIELD_VALUE}`:
                    const updateValueFieldAction: IUpdateFieldValueAction = action as IUpdateFieldValueAction;

                    return {
                        ...state,
                        value: updateValueFieldAction.payload.newValue,
                    };

                // This case is used to reset the state of the checkbox field back to the initial
                // (display) state with no error message. This action does not manipulate the
                // control's value.
                case `${fieldName.toUpperCase()}_${CheckboxFieldActionType.RESET_CHECKBOX_FIELD}`:
                    return {
                        ...state,
                        errorMessage: "",
                    };
                default:
                    return state;
            }
        };

export default checkboxFieldControlReducer;

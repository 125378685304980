import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import React from "react";

import { loginRequest } from "auth/authConfig";
import Auth from "auth/auth";
import Chrome from "chrome/chrome";
import Content from "content/content";
import Spinner from "common/components/spinner/spinner";
import StringOrNull from "common/types/stringOrNull";

export default function App(): JSX.Element {
    const { instance, inProgress } = useMsal();

    React.useEffect(() => {
        const callbackId: StringOrNull = Auth.getEventCallback();

        return () => {
            callbackId && instance.removeEventCallback(callbackId);
        };
    }, [instance]);

    return (
        <main className="screenHeight">
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={loginRequest}
                loadingComponent={() => <Spinner renderInOverlay />}
            >
                {
                    inProgress !== InteractionStatus.None
                        ? <Spinner renderInOverlay />
                        : <Chrome content={<Content />} />
                }
            </MsalAuthenticationTemplate>
        </main>
    );
}

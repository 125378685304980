import { combineReducers, Reducer } from "redux";

import checkboxFieldControlReducer from "common/fields/checkboxField/duck/checkboxFieldControlReducer";
import datePickerFieldControlReducer from "common/fields/datePickerField/duck/datePickerFieldControlReducer";
import dropdownFieldControlReducer from "common/fields/dropdownField/duck/dropdownFieldControlReducer";
import FieldNames from "content/registration/personal/aboutYou/enums/registrationPersonalAboutYouFormFieldNames";
import IRegistrationPersonalAboutYouFormState from "content/registration/personal/aboutYou/duck/interfaces/IRegistrationPersonalAboutYouFormState";
import textFieldControlReducer from "common/fields/textField/duck/textFieldControlReducer";

const registrationPersonalAboutYouFormReducer: Reducer<IRegistrationPersonalAboutYouFormState> =
    combineReducers<IRegistrationPersonalAboutYouFormState>({
        // Personal information
        countryOfResidenceDropdownFieldState: dropdownFieldControlReducer(FieldNames.countryOfResidence),
        firstNameTextFieldState: textFieldControlReducer(FieldNames.firstName),
        lastNameTextFieldState: textFieldControlReducer(FieldNames.lastName),
        dobDatePickerFieldState: datePickerFieldControlReducer(FieldNames.dob),
        citizenshipDropdownFieldState: dropdownFieldControlReducer(FieldNames.citizenship),
        socialInsuranceNumberTextFieldState: textFieldControlReducer(FieldNames.socialInsuranceNumber),
        countryCodeDropdownFieldState: dropdownFieldControlReducer(FieldNames.countryCode),
        mobileNumberTextFieldState: textFieldControlReducer(FieldNames.mobileNumber),
        // Current address
        countryDropdownFieldState: dropdownFieldControlReducer(FieldNames.country),
        addressLine1TextFieldState: textFieldControlReducer(FieldNames.addressLine1),
        addressLine2TextFieldState: textFieldControlReducer(FieldNames.addressLine2),
        cityTextFieldState: textFieldControlReducer(FieldNames.city),
        stateTextFieldState: textFieldControlReducer(FieldNames.state),
        zipCodeTextFieldState: textFieldControlReducer(FieldNames.zipCode),
        // Other details
        employmentStatusDropdownFieldState: dropdownFieldControlReducer(FieldNames.employmentStatus),
        occupationTextFieldState: textFieldControlReducer(FieldNames.occupation),
        employerTextFieldState: textFieldControlReducer(FieldNames.employer),
        sourceOfFundsDropdownFieldState: dropdownFieldControlReducer(FieldNames.sourceOfFunds),
        otherSourceOfFundsTextFieldState: textFieldControlReducer(FieldNames.otherSourceOfFunds),
        estimatedNumberOfPaymentsPerMonthDropdownFieldState:
            dropdownFieldControlReducer(FieldNames.estimatedNumberOfPaymentsPerMonth),
        estimatedAverageAmountOfPaymentsPerMonthDropdownFieldState:
            dropdownFieldControlReducer(FieldNames.estimatedAverageAmountOfPaymentsPerMonth),
        // Terms and Conditions
        certifyCheckboxFieldState: checkboxFieldControlReducer(FieldNames.certify),
        agreeCheckboxFieldState: checkboxFieldControlReducer(FieldNames.agree),
        readCheckboxFieldState: checkboxFieldControlReducer(FieldNames.read),
        declareCheckboxFieldState: checkboxFieldControlReducer(FieldNames.declare),
    });

export default registrationPersonalAboutYouFormReducer;
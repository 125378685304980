import { Overlay as FluentUIOverlay, Spinner as FluentUISpinner } from "@fluentui/react";
import React from "react";

import ISpinnerProps from "common/components/spinner/interfaces/ISpinnerProps";

import styles from "common/components/spinner/spinner.styles";

export default function Spinner(props: ISpinnerProps): JSX.Element {
    if (!props.renderInOverlay) {
        return <FluentUISpinner {...props.baseSpinnerProps} />;
    }

    return (
        <FluentUIOverlay {...props.overlayProps}>
            <div className={styles.spinnerWrapper}>
                <FluentUISpinner {...props.baseSpinnerProps} />
            </div>
        </FluentUIOverlay>
    );
}
Spinner.defaultProps = {
    renderInOverlay: false,
    overlayProps: {
        styles: styles.overlay,
        isDarkThemed: true,
    },
} as Partial<ISpinnerProps>;
import { initializeIcons, loadTheme } from "@fluentui/react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "serviceWorker";
import { MsalProvider } from "@azure/msal-react";
import App from "app";
import AsyncStoreUtils from "utils/asyncStoreUtils";
import Auth from "auth/auth";
import simplypayTheme from "simplypayTheme";

import registerFonts from "common/style/fonts/fonts.styles";
import "common/style/style.scss";

// Initializing Office Ui Fabric icons
initializeIcons();
// Loading simplypay theme
loadTheme(simplypayTheme);
// Registring fonts
registerFonts();

// Redux store
const store = AsyncStoreUtils.configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <MsalProvider instance={Auth.instance}>
          <App />
        </MsalProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

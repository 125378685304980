import { combineReducers } from "redux";

import IPayablesNewPayableState from "content/payables/payablesNewPayable/duck/interfaces/IPayablesNewPayableState";
import payablesNewPayableContentReducer from "content/payables/payablesNewPayable/duck/payablesNewPayableContentReducer";
import payablesNewPayableDetailsReducer from "content/payables/payablesNewPayable/payableDetails/duck/payablesNewPayableDetailsReducer";
import payablesNewPayableReviewPayableReducer from "content/payables/payablesNewPayable/reviewPayable/duck/payablesNewPayableReviewPayableReducer";

const payablesNewPayableReducer = combineReducers<IPayablesNewPayableState>({
    contentState: payablesNewPayableContentReducer,
    payableDetailsState: payablesNewPayableDetailsReducer,
    reviewPayableState: payablesNewPayableReviewPayableReducer,
});

export default payablesNewPayableReducer;
import { ICommandBarStyleProps, ICommandBarStyles, IPanelStyleProps, IPanelStyles, IRawStyle, IStackItemTokens, IStackStyles, IStyleFunctionOrObject } from "@fluentui/react";

import { medium24 } from "common/style/textVariants.styles";
import flexbox from "common/style/flexbox/flexbox.styles";

const btnStyle = (iconColor: string): IRawStyle => {
    const iconStyle: IRawStyle = {
        ".ms-Icon": {
            color: iconColor,
            fontSize: 14,
        }
    };

    return {
        "button": {
            ...iconStyle,
            minWidth: 44,
            ":hover": iconStyle,
            ":active": iconStyle,
        }
    };
};

const panel: IStyleFunctionOrObject<IPanelStyleProps, IPanelStyles> =
    (props: IPanelStyleProps): Partial<IPanelStyles> => ({
        overlay: {
            backgroundColor: "#1e1e1e80",
        },
        main: {
            backgroundColor: props.theme.semanticColors.menuBackground,
        },
        navigation: {
            flexWrap: "wrap",
            flexDirection: "column",
            height: "auto",
            ...btnStyle(props.theme.palette.neutralPrimary),
        },
        subComponentStyles: {
            closeButton: {
                root: {
                    height: 44,
                },
                icon: {
                    fontSize: 14,
                    color: props.theme.palette.neutralPrimary,
                }
            }
        },
        header: {
            alignSelf: "center",
            padding: "0 30px",
        },
        headerText: [
            medium24,
            {
                marginTop: 30,
                marginBottom: 30,
                lineHeight: "normal",
            }
        ],
        scrollableContent: {
            overflow: "hidden",
            ...flexbox(),
        },
        content: {
            padding: "0px 30px",
            marginBottom: 20,
            flexGrow: 1,
            "@media (min-height: 480px)": {
                minHeight: "auto",
            }
        },
        commands: {
            paddingTop: 0,
            backgroundColor: props.theme.semanticColors.menuBackground,
            "@media (min-height: 480px)": {
                backgroundColor: props.theme.semanticColors.menuBackground,
            }
        }
    });

const commandBar: IStyleFunctionOrObject<ICommandBarStyleProps, ICommandBarStyles> =
    (props: ICommandBarStyleProps): Partial<ICommandBarStyles> => ({
        root: {
            padding: 0,
            backgroundColor: props.theme.semanticColors.menuBackground,
            ...btnStyle(props.theme.palette.neutralPrimary),
            ".ms-Button--commandBar": {
                backgroundColor: props.theme.semanticColors.menuBackground,
                "hover": {
                    backgroundColor: props.theme.palette.neutralLighter,
                }
            },
        },
    });

const spinnerStack: IStackStyles = {
    root: {
        position: "absolute",
        zIndex: 2,
        top: 0,
        left: 0,
        width: "100%",
        backgroundColor: "#fff6",
    },
};

const messageBarStackItemTokens = (isHeaderRendered: boolean): IStackItemTokens => ({
    padding: isHeaderRendered
        ? "0 30px"
        : "0 30px 1rem 30px"
});

const panelStyles = {
    panel,
    commandBar,
    spinnerStack,
    messageBarStackItemTokens,
};

export default panelStyles;
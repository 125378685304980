import { combineReducers } from "redux";

import ActionType from "content/common/fundingAccount/panelSelectType/duck/actionTypes/fundingAccountSelectTypeActionType";
import fundingAccountAddCreditCardReducer from "content/common/fundingAccount/panelAddCreditCard/duck/fundingAccountAddCreditCardReducer";
import fundingAccountSelectVerificationTypeReducer from "content/common/fundingAccount/panelSelectVerificationType/duck/fundingAccountSelectVerificationTypeReducer";
import IFundingAccountSelectTypeAction from "content/common/fundingAccount/panelSelectType/duck/actions/interfaces/IFundingAccountSelectTypeAction";
import IFundingAccountSelectTypeState from "content/common/fundingAccount/panelSelectType/duck/interfaces/IFundingAccountSelectTypeState";
import IFundingAccountTypeSelectionState from "content/common/fundingAccount/panelSelectType/duck/interfaces/IFundingAccountTypeSelectionState";

type Action = IFundingAccountSelectTypeAction;
type State = IFundingAccountTypeSelectionState;

const defaultState: State = {
    selectedType: null,
};

function fundingAccountTypeSelectionReducer(state: State = defaultState, action: Action): State {
    switch (action.type) {
        case ActionType.FUNDING_ACCOUNT_SELECT_TYPE:
            return {
                selectedType: action.payload.cardType,
            };

        default:
            return state;
    }
}

const fundingAccountSelectTypeReducer = combineReducers<IFundingAccountSelectTypeState>({
    selectionState: fundingAccountTypeSelectionReducer,
    creditCardFormState: fundingAccountAddCreditCardReducer,
    bankAccountVerificationState: fundingAccountSelectVerificationTypeReducer,
});

export default fundingAccountSelectTypeReducer;
import { combineReducers } from "redux";

import datePickerFieldControlReducer from "common/fields/datePickerField/duck/datePickerFieldControlReducer";
import dropdownFieldControlReducer from "common/fields/dropdownField/duck/dropdownFieldControlReducer";
import FieldNames from "content/payables/payablesNewPayable/enums/payablesNewPayableFormFieldNames";
import IPayablesNewPayableDetailsFormState from "content/payables/payablesNewPayable/payableDetails/duck/interfaces/IPayablesNewPayableDetailsFormState";
import textFieldControlReducer from "common/fields/textField/duck/textFieldControlReducer";

const payablesNewPayableDetailsFormReducer = combineReducers<IPayablesNewPayableDetailsFormState>({
    // Domestic fields
    paymentDateDatePickerFieldState: datePickerFieldControlReducer(FieldNames.paymentDate),
    amountTextFieldState: textFieldControlReducer(FieldNames.amount),
    statementDescriptorTextFieldState: textFieldControlReducer(FieldNames.statementDescriptor),

    // International fields
    youSendTextFieldState: textFieldControlReducer(FieldNames.youSend),
    recipientGetsTextFieldState: textFieldControlReducer(FieldNames.recipientGets),
    recipientCurrencyDropdownFieldState: dropdownFieldControlReducer(FieldNames.recipentCurrency),

    // Common fields
    paymentPurposeDropdownFieldState: dropdownFieldControlReducer(FieldNames.paymentPurpose),
});

export default payablesNewPayableDetailsFormReducer;
import { CommandBar, ICommandBarItemProps } from "@fluentui/react";
import React from "react";

import UserButton from "common/components/userButton/userButton";

import topBarStyles from "chrome/topBar/topBar.styles";

const farItems: ICommandBarItemProps[] = [
    {
        key: "user",
        onRender: () => <UserButton />
    }
];

export default function TopBar(): JSX.Element {
    return (
        <CommandBar items={[]} farItems={farItems} styles={topBarStyles.commandBar} />
    );
}
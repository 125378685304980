import OnboardingActions from "content/onboarding/duck/actions/types/onboardingActions";
import OnboardingActionType from "content/onboarding/duck/actionTypes/onboardingActionType";
import State from "content/onboarding/duck/interfaces/IOnboardingState";

const defaultState: State = {
    isLoading: true,
    errorMessage: "",
    stepList: [],
    overallStatus: null,
    files: [],
};

const onboardingReducer = (state = defaultState, action: OnboardingActions): State => {
    switch (action.type) {
        case OnboardingActionType.ONBOARDING_REQUEST_DATA:
            return {
                ...state,
                isLoading: true,
            };

        case OnboardingActionType.ONBOARDING_LOAD_STEPS:
            return {
                ...state,
                isLoading: false,
                stepList: action.payload.stepList,
                overallStatus: action.payload.status,
            };

        case OnboardingActionType.ONBOARDING_LOAD_FILES:
            return {
                ...state,
                isLoading: false,
                files: action.payload.files,
            };

        case OnboardingActionType.ONBOARDING_DISPLAY_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload.errorMessage,
            };

        default:
            return state;
    }
};

export default onboardingReducer;
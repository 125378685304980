import PayableDetailsActions from "content/payableDetails/duck/actions/types/payableDetailsActions";
import PayableDetailsActionType from "content/payableDetails/duck/actionTypes/payableDetailsActionType";
import State from "content/payableDetails/duck/interfaces/IPayableDetailsState";

const defaultState: State = {
    isLoading: false,
    errorMessage: "",
};

export default function payableReducer(state = defaultState, action: PayableDetailsActions): State {
    switch (action.type) {
        case PayableDetailsActionType.PAYABLE_DETAILS_REQUEST_DATA:
            return {
                ...state,
                isLoading: true,
                errorMessage: "",
            };

        case PayableDetailsActionType.PAYABLE_DETAILS_HIDE_SPINNER:
            return {
                ...state,
                isLoading: false,
            };

        case PayableDetailsActionType.PAYABLE_DETAILS_DISPLAY_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload.errorMessage,
            };

        default:
            return state;
    }
}
import React from "react";

import IError from "common/types/IError";

import errorBoundaryStyles from "common/components/errorBoundary/errorBoundary.styles";

export default class ErrorBoundary extends React.Component<React.PropsWithChildren<Record<never, never>>, IError> {
    public state: IError = {
        errorMessage: "",
    };

    public componentDidCatch(error: Error): void {
        // You can also log the error to an error reporting service 
        this.setState({ errorMessage: error.name + " -> " + error.message });
    }

    public render(): React.ReactNode {
        if (this.state.errorMessage) {
            // You can render any custom fallback UI    
            return (
                <div className={errorBoundaryStyles.root}>
                    <h3><pre>Error: {this.state.errorMessage}</pre></h3>
                </div>
            );
        }

        return this.props.children;
    }
}
import ConstantUtils from "utils/constantUtils";

const authConstants = {
    clientId: ConstantUtils.msalClientId,
    scopes: ["openid", "offline_access", ConstantUtils.msalClientId],

    // local storage constants
    userEmail: "userEmail",
    accountId: "accountId",

    // session storage constants
    claims: "claims",

    // Error codes
    forgotPasswordCode: "AADB2C90118",
    userCancellationCode: "AADB2C90091",
    ssoSilentCode: "silent_sso_error",
};

export default authConstants;
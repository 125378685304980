import { createTheme } from "@fluentui/react";

import FontFamily from "common/style/fonts/fontFamily";

const backgroundWhite = "#fbfbfc";
const themePrimary = "#03c9e9";
const themePrimaryLight = "#67DEF2";
const pureWhite = "#fff";
const textColor = "#1e1e1e";
const menuItemBackgroundHovered = "#F6F7F9";
const errorText = "#C22929";
const inputPlaceholderText = "#B2C2CD";
const disabledBackground = "#F8F8FB";
const inputIcon = "#91A1BB";
const borderColor = "#F1F3F4";

const simplypayTheme = createTheme({
  defaultFontStyle: {
    fontFamily: FontFamily.GraphikRegular,
    fontWeight: "normal",
    MozOsxFontSmoothing: "antialiased",
    WebkitFontSmoothing: "antialiased",
  },
  palette: {
    themePrimary,
    themeLighterAlt: "#f4fdfe",
    themeLighter: "#d4f6fb",
    themeLight: "#aeeef8",
    themeTertiary: "#62def1",
    themeSecondary: "#5e3bed",
    themeDarkAlt: "#02b5d1",
    themeDark: "#0299b0",
    themeDarker: "#017182",
    neutralLighterAlt: "#f6f6f6",
    neutralLighter: "#f2f2f2",
    neutralLight: "#e8e8e8",
    neutralQuaternaryAlt: "#d8d8d8",
    neutralQuaternary: "#cecece",
    neutralTertiaryAlt: "#c6c6c6",
    neutralTertiary: "#b3b3b3",
    neutralSecondary: "#9a9a9a",
    neutralPrimaryAlt: "#818181",
    neutralPrimary: textColor,
    neutralDark: "#505050",
    black: "#373737",
    white: backgroundWhite,
  },
  semanticColors: {
    // Default button
    buttonBackground: pureWhite,
    buttonBackgroundHovered: themePrimary,
    buttonBackgroundPressed: themePrimary,
    buttonBorder: themePrimary,
    buttonText: themePrimary,
    buttonTextHovered: pureWhite,

    // Primary button
    primaryButtonBackgroundHovered: themePrimaryLight,
    primaryButtonBackgroundPressed: themePrimaryLight,

    linkHovered: themePrimary,

    // Callout buttons(menu items)
    menuItemTextHovered: textColor,
    menuItemBackgroundHovered,
    menuBackground: pureWhite,

    // Card
    cardShadow: "#1e1e1e12",

    // Input
    errorText,
    inputPlaceholderText,
    inputBorder: inputPlaceholderText,
    inputBorderHovered: inputPlaceholderText,
    inputBackground: pureWhite,
    disabledBackground,
    inputIcon,
    bodyDivider: borderColor
  },
});

export default simplypayTheme;
import { Reducer } from "redux";

import DatePickerFieldActionType from "common/fields/datePickerField/duck/actionTypes/datePickerFieldActionType";
import IDisplayFieldErrorAction from "common/fields/common/duck/actions/interfaces/IDisplayFieldErrorAction";
import IFieldControlState from "common/fields/common/interfaces/IFieldControlState";
import IResetFieldAction from "common/fields/common/duck/actions/interfaces/IResetFieldAction";
import IUpdateFieldValueAction from "common/fields/common/duck/actions/interfaces/IUpdateFieldValueAction";

type Action = IDisplayFieldErrorAction | IUpdateFieldValueAction | IResetFieldAction;

type CreateDatePickerFieldControlReducer = (fieldName: string) => Reducer<IFieldControlState, Action>;

const datePickerFieldControlDefaultState: IFieldControlState = {
    value: "",
    errorMessage: ""
};

const datePickerFieldControlReducer: CreateDatePickerFieldControlReducer =
    (fieldName: string): Reducer<IFieldControlState, Action> =>
        (state: IFieldControlState = datePickerFieldControlDefaultState, action: Action): IFieldControlState => {

            switch (action.type) {
                // Case when the server request fails. This should hide the datePicker field and display the
                // error message from the action's payload.
                case `${fieldName.toUpperCase()}_${DatePickerFieldActionType.DISPLAY_DATEPICKER_FIELD_ERROR}`:
                    // Casting because we are using string compare for switch type so TypeScript
                    // cannot infer the correct typing for action.
                    const displayDatePickerFieldErrorAction: IDisplayFieldErrorAction =
                        action as IDisplayFieldErrorAction;

                    return {
                        ...state,
                        errorMessage: displayDatePickerFieldErrorAction.payload.errorMessage,
                    };

                case `${fieldName.toUpperCase()}_${DatePickerFieldActionType.UPDATE_DATEPICKER_FIELD_VALUE}`:
                    // Casting because we are using string compare for switch type so TypeScript
                    // cannot infer the correct typing for action.
                    const updateValueDatePickerFieldAction: IUpdateFieldValueAction =
                        action as IUpdateFieldValueAction;

                    return {
                        ...state,
                        // Never want to set the value to null or undefined. The server should not
                        // return with null properties, but just in case, the client will ensure
                        // that the components get empty string rather then null or undefined.
                        value: updateValueDatePickerFieldAction.payload.newValue
                            ? updateValueDatePickerFieldAction.payload.newValue
                            : "",
                    };

                // This case is used to reset the fields back to the default (display) mode
                // without any error messages.
                case `${fieldName.toUpperCase()}_${DatePickerFieldActionType.RESET_DATEPICKER_FIELD}`:
                    return {
                        ...state,
                        errorMessage: "",
                    };
                default:
                    return state;
            }
        };

export default datePickerFieldControlReducer;

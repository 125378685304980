import { ICommandBarStyles } from "@fluentui/react";

import simplypayTheme from "simplypayTheme";

const commonSqureValue = 32;

const commandBar: ICommandBarStyles = {
    root: {
        backgroundColor: simplypayTheme.semanticColors.menuBackground,
        alignItems: "center",
        padding: "14px 26px 14px 15px",
        height: 60,
        boxShadow: "0px 7px 12px #E1E4EA21",
    },
    primarySet: { height: commonSqureValue },
    secondarySet: { height: commonSqureValue },
};

const topBarStyles = {
    commandBar,
};

export default topBarStyles;


import Action from "content/recipients/panelWizard/duck/actions/interfaces/IRecipientsWizardSelectTabAction";
import RecipientsWizardActionType from "content/recipients/panelWizard/duck/actionTypes/recipientsWizardActionType";
import RecipientsWizardTabs from "content/recipients/panelWizard/enums/recipientsWizardTabs";
import State from "content/recipients/panelWizard/duck/interfaces/IRecipientsWizardState";

const defaultState: State = {
    tab: RecipientsWizardTabs.Recipient,
};

export default function recipientsWizardReducer(state = defaultState, action: Action): State {
    switch (action.type) {
        case RecipientsWizardActionType.RECIPIENTS_WIZARD_SELECT_TAB:
            return {
                tab: action.payload.tab,
            };

        default:
            return state;
    }
}
import { combineReducers } from "redux";

import FundingAccountVerifyMicroDepositFieldNames from "content/common/fundingAccount/panelVerifyMicroDeposit/enums/fundingAccountVerifyMicroDepositFieldNames";
import textFieldControlReducer from "common/fields/textField/duck/textFieldControlReducer";

const fundingAccountVerifyMicroDepositReducer = combineReducers({
    firstMicroDepositTextFieldState: textFieldControlReducer(
        FundingAccountVerifyMicroDepositFieldNames.firstMicroDeposit),
    secondMicroDepositTextFieldState: textFieldControlReducer(
        FundingAccountVerifyMicroDepositFieldNames.secondMicroDeposit),
});

export default fundingAccountVerifyMicroDepositReducer;
import Action from "content/common/fundingAccount/panelManualVerificationWrapper/duck/actions/interfaces/IFundingAccountManualVerificationLoadDataAction";
import FundingAccountManualVerificationActionType from "content/common/fundingAccount/panelManualVerificationWrapper/duck/actionTypes/fundingAccountManualVerificationActionType";
import State from "content/common/fundingAccount/panelManualVerificationWrapper/duck/interfaces/IFundingAccountManualVerificationState";

const defaultState: State = {
    requirements: null,
};

const fundingAccountManualVerificationReducer = (state: State = defaultState, action: Action): State => {
    switch (action.type) {
        case FundingAccountManualVerificationActionType.FUNDING_ACCOUNT_MANUAL_VERIFICATION_LOAD_DATA:
            return {
                requirements: action.payload.requirements,
            };
            
        default:
            return state;
    }
};

export default fundingAccountManualVerificationReducer;
import { combineReducers, Reducer } from "redux";

import AutocompleteAddressActionType from "common/components/autocompleteAddress/duck/actionTypes/autocompleteAddressActionType";
import autocompleteAddressFieldNames from "common/components/autocompleteAddress/enums/autocompleteAddressFieldNames";
import dropdownFieldControlReducer from "common/fields/dropdownField/duck/dropdownFieldControlReducer";
import IAutocompleteAddressContentState from "common/components/autocompleteAddress/duck/interfaces/IAutocompleteAddressContentState";
import IAutocompleteAddressFormState from "common/components/autocompleteAddress/duck/interfaces/IAutocompleteAddressFormState";
import IAutocompleteAddressState from "common/components/autocompleteAddress/duck/interfaces/IAutocompleteAddressState";
import IAutocompleteAddressToggleShimmerAction from "common/components/autocompleteAddress/duck/actions/interfaces/IAutocompleteAddressToggleShimmerAction";
import textFieldControlReducer from "common/fields/textField/duck/textFieldControlReducer";

const defaultState: IAutocompleteAddressContentState = {
    showShimmer: false
};

const contentReducer: Reducer<IAutocompleteAddressContentState, IAutocompleteAddressToggleShimmerAction> =
    (state: IAutocompleteAddressContentState = defaultState, action: IAutocompleteAddressToggleShimmerAction)
        : IAutocompleteAddressContentState => {
        switch (action.type) {
            case AutocompleteAddressActionType.AUTOCOMPLETE_ADDRESS_TOGGLE_SHIMMER:
                return {
                    ...state,
                    showShimmer: action.payload.showShimmer
                };

            default:
                return state;
        }
    };

export default function autocompleteAddressReducer(entity: string): Reducer<IAutocompleteAddressState> {
    const fieldNames = autocompleteAddressFieldNames(entity);

    const formReducer = combineReducers<IAutocompleteAddressFormState>({
        countryDropdownFieldState: dropdownFieldControlReducer(fieldNames.country),
        line1TextFieldState: textFieldControlReducer(fieldNames.line1),
        line2TextFieldState: textFieldControlReducer(fieldNames.line2),
        cityTextFieldState: textFieldControlReducer(fieldNames.city),
        stateDropdownFieldState: dropdownFieldControlReducer(fieldNames.state),
        zipCodeTextFieldState: textFieldControlReducer(fieldNames.zipCode),
    });

    return combineReducers<IAutocompleteAddressState>({
        contentState: contentReducer,
        formState: formReducer,
    });
}

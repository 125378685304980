import { combineReducers, Reducer } from "redux";

import Action from "content/registration/personal/aboutYou/duck/actions/types/registrationPersonalAboutYouActions";
import IRegistrationPersonalAboutYouContentState from "content/registration/personal/aboutYou/duck/interfaces/IRegistrationPersonalAboutYouContentState";
import IRegistrationPersonalAboutYouState from "content/registration/personal/aboutYou/duck/interfaces/IRegistrationPersonalAboutYouState";
import RegistrationPersonalAboutYouActionType from "content/registration/personal/aboutYou/duck/actionTypes/registrationPersonalAboutYouActionType";
import registrationPersonalAboutYouFormReducer from "content/registration/personal/aboutYou/duck/registrationPersonalAboutYouFormReducer";

type State = IRegistrationPersonalAboutYouContentState;

const defaultState: State = {
    errorMessage: "",
    isLoading: false,
};

const registrationPersonalAboutYouContentReducer: Reducer<State, Action> =
    (state = defaultState, action: Action): State => {
        switch (action.type) {
            case RegistrationPersonalAboutYouActionType.REGISTRATION_PERSONAL_ABOUT_YOU_REQUEST_DATA:
                return {
                    ...state,
                    isLoading: true,
                };

            case RegistrationPersonalAboutYouActionType.REGISTRATION_PERSONAL_ABOUT_YOU_DISPLAY_ERROR:
                return {
                    ...state,
                    isLoading: false,
                    errorMessage: action.payload.errorMessage,
                };

            case RegistrationPersonalAboutYouActionType.REGISTRATION_PERSONAL_ABOUT_YOU_HIDE_SPINNER:
                return {
                    ...state,
                    isLoading: false,
                };

            default:
                return state;
        }
    };

const registrationPersonalAboutYouReducer = combineReducers<IRegistrationPersonalAboutYouState>({
    contentState: registrationPersonalAboutYouContentReducer,
    formState: registrationPersonalAboutYouFormReducer,
});

export default registrationPersonalAboutYouReducer;

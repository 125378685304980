import { Reducer } from "redux";

import DropdownFieldActionType from "common/fields/dropdownField/duck/actionTypes/dropdownFieldActionType";
import IDisplayFieldErrorAction from "common/fields/common/duck/actions/interfaces/IDisplayFieldErrorAction";
import IFieldControlState from "common/fields/common/interfaces/IFieldControlState";
import IResetFieldAction from "common/fields/common/duck/actions/interfaces/IResetFieldAction";
import IUpdateFieldValueAction from "common/fields/common/duck/actions/interfaces/IUpdateFieldValueAction";

type Action = IDisplayFieldErrorAction | IUpdateFieldValueAction | IResetFieldAction;

type CreateDropdownFieldControlReducer = (fieldName: string) => Reducer<IFieldControlState, Action>;

const dropdownControlDefaultState: IFieldControlState = {
    value: "",
    errorMessage: "",
};

const dropdownFieldControlReducer: CreateDropdownFieldControlReducer =
    (fieldName: string): Reducer<IFieldControlState, Action> =>
        (state: IFieldControlState = dropdownControlDefaultState, action: Action): IFieldControlState => {
            switch (action.type) {
                // Case when the server request fails. This should enable the dropdown field and display
                // the error message from the action's payload.
                case `${fieldName.toUpperCase()}_${DropdownFieldActionType.DISPLAY_DROPDOWN_FIELD_ERROR}`:
                    const displayFieldErrorAction: IDisplayFieldErrorAction = action as IDisplayFieldErrorAction;

                    return {
                        ...state,
                        errorMessage: displayFieldErrorAction.payload.errorMessage,
                    };

                case `${fieldName.toUpperCase()}_${DropdownFieldActionType.UPDATE_DROPDOWN_FIELD_VALUE}`:
                    const updateValueFieldAction: IUpdateFieldValueAction = action as IUpdateFieldValueAction;

                    return {
                        ...state,
                        value: updateValueFieldAction.payload.newValue,
                    };

                // This case is used to reset the state of the dropdown field back to the initial
                // (display) state with no error message.
                case `${fieldName.toUpperCase()}_${DropdownFieldActionType.RESET_DROPDOWN_FIELD}`:
                    return {
                        ...state,
                        errorMessage: "",
                    };

                default:
                    return state;
            }
        };

export default dropdownFieldControlReducer;

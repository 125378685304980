import ChromeActionType from "chrome/duck/actionTypes/chromeActionType";
import IChromeManagePartsVisibilityAction from "chrome/duck/actions/interfaces/IChromeManagePartsVisibilityAction";
import State from "chrome/duck/interfaces/IChromeContentState";

type Action = IChromeManagePartsVisibilityAction;

const defaultState: State = {
    shouldShowHamburgerMenuAndTopBar: false,
};

const chromeContentReducer = (state: State = defaultState, action: Action): State => {
    switch (action.type) {
        case ChromeActionType.CHROME_MANAGE_PARTS_VISIBILITY:
            return {
                ...state,
                shouldShowHamburgerMenuAndTopBar: action.payload.shouldShowHamburgerMenuAndTopBar,
            };

        default:
            return state;
    }
};

export default chromeContentReducer;
import { applyMiddleware, combineReducers, createStore, Reducer, Store } from "redux";
import React from "react";
import Thunk from "redux-thunk";

import chromeReducer from "chrome/duck/chromeReducer";
import contentReducer from "content/duck/contentReducer";
import dataReducer from "content/data/duck/dataReducer";
import Entities from "content/common/enums/entities";
import FundingAccountReducer from "content/common/fundingAccount/duck/fundingAccountReducer";
import IAppAsyncState from "duck/interfaces/IAppAsyncState";
import IAppState from "duck/interfaces/IAppState";
import listPageReducer from "common/components/listPage/duck/listPageReducer";
import panelUploadFilesReducer from "common/components/panelUploadFiles/duck/panelUploadFilesReducer";
import payableDetailsReducer from "content/payableDetails/duck/payableDetailsReducer";
import payablesNewPayableReducer from "content/payables/payablesNewPayable/duck/payablesNewPayableReducer";
import recipientDetailsReducer from "content/recipientDetails/duck/recipientDetailsReducer";
import recipientsReducer from "content/recipients/duck/recipientsReducer";
import registrationReducer from "content/registration/duck/registrationReducer";
import walletsReducer from "content/wallets/duck/walletsReducer";

interface IAsyncStore extends Store {
    /**
     * A dictionary to keep track of the registered async reducers.
     */
    asyncReducers: Record<string, Reducer | null>;

    /**
     * Adds the async reducer, and creates a new combined reducer.
     * @param key A key of the state where reducer's data will be stored. 
     * @param reducer A reducer that need to inject.
     */
    injectReducer(key: string, reducer: Reducer): void;

    emptyReducer(key: string): void;
}

/**
 * A utility class that helps to add an async reducer.
 */
export default class AsyncStoreUtils {
    static readonly store =
        createStore(AsyncStoreUtils.createReducer(), applyMiddleware(Thunk)) as IAsyncStore;

    /**
     * Configure the store. It will add the empty object for `asyncReducers` and a function to
     * inject a new reducer.
     */
    static configureStore(): IAsyncStore {
        // Add a dictionary to keep track of the registered async reducers
        AsyncStoreUtils.store.asyncReducers = {};

        // Create an inject reducer function
        // This function adds the async reducer, and creates a new combined reducer.
        AsyncStoreUtils.store.injectReducer = (key: string, reducer: Reducer): void => {
            AsyncStoreUtils.store.asyncReducers[key] = reducer;
            AsyncStoreUtils.store.replaceReducer(AsyncStoreUtils.createReducer(AsyncStoreUtils.store.asyncReducers));
        };

        AsyncStoreUtils.store.emptyReducer = (key: string): void => {
            AsyncStoreUtils.store.asyncReducers[key] = null;
            AsyncStoreUtils.store.replaceReducer(AsyncStoreUtils.createReducer(AsyncStoreUtils.store.asyncReducers));
        };

        // Return the modified store.
        return AsyncStoreUtils.store;
    }

    /**
     * A higher-order component to add reducer and render specific component.
     * It returns a function that accepts a single parameter, WrappedComponent. 
     * That’s expected to be a valid React component.
     * @param key Becomes a key in redux state tree.
     * @param reducer A reducer to add.
     */
    static withReducer<P>(key: string, reducer: Reducer) {
        return (WrappedComponent: React.ComponentType<P>) =>
            (props: P): JSX.Element => {
                React.useEffect(() => {
                    AsyncStoreUtils.store.injectReducer(key, reducer);
                }, []);

                return <WrappedComponent {...props} />;
            };
    }

    /**
     * Returns combine reducer from both static and async reducer.
     * @param asyncReducers An async reducer need to be combine within the existing reducers.
     */
    private static createReducer(asyncReducers?: Record<string, Reducer | null>): Reducer {
        /**
         * The Reducers that will always be present in the application
         */
        const staticReducers: Record<Exclude<keyof IAppState, keyof IAppAsyncState>, Reducer> = {
            chromeState: chromeReducer,
            contentState: contentReducer,
            registration: registrationReducer,
            payablesState: listPageReducer(Entities.PAYABLES),
            payablesNewPayableState: payablesNewPayableReducer,
            payableDetailsState: payableDetailsReducer,
            recipientsState: recipientsReducer,
            recipientDetailsState: recipientDetailsReducer,
            dataState: dataReducer as Reducer,
            panelFilesState: panelUploadFilesReducer,
            fundingAccountState: FundingAccountReducer,
            walletsState: walletsReducer
        };

        return combineReducers({
            ...staticReducers,
            ...asyncReducers,
        });
    }
}
import { merge } from "@fluentui/react";
import { Reducer } from "redux";

import DataActionType from "content/data/duck/actionTypes/dataActionType";
import IDataCreateUpdateAccountsAction from "content/data/duck/actions/interfaces/IDataCreateUpdateAccountsAction";
import IDataCreateUpdateOnboardingFilesAction from "content/data/duck/actions/interfaces/IDataCreateUpdateOnboardingFilesAction";
import IDataCreateUpdatePayableRecipientsAction from "content/data/duck/actions/interfaces/IDataCreateUpdatePayableRecipientsAction";
import IDataCreateUpdatePayablesAction from "content/data/duck/actions/interfaces/IDataCreateUpdatePayablesAction";
import IDataCreateUpdateRecipientsAction from "content/data/duck/actions/interfaces/IDataCreateUpdateRecipientsAction";
import IDataCreateUpdateStatesAction from "content/data/duck/actions/interfaces/IDataCreateUpdateStatesAction";
import IDataState from "content/data/duck/interfaces/IDataState";

type Action = IDataCreateUpdateOnboardingFilesAction |
    IDataCreateUpdatePayablesAction |
    IDataCreateUpdateRecipientsAction |
    IDataCreateUpdateAccountsAction |
    IDataCreateUpdatePayableRecipientsAction |
    IDataCreateUpdateStatesAction;

const defaultState: IDataState = {
    onboardingFiles: {},
    payables: {},
    recipients: {},
    fundingAccounts: {},
    payableRecipients: {},
    stateDropdownOptions: {}
};

const dataReducer: Reducer<IDataState, Action> = (state: IDataState = defaultState, action: Action): IDataState => {
    switch (action.type) {
        case DataActionType.DATA_CREATE_UPDATE_ONBOARDING_FILES:
            return {
                ...state,
                onboardingFiles: merge({}, state.onboardingFiles, action.payload.data),
            };

        case DataActionType.DATA_CREATE_UPDATE_PAYABLES:
            return {
                ...state,
                payables: merge({}, state.payables, action.payload.data),
            };

        case DataActionType.DATA_CREATE_UPDATE_ACCOUNTS:
            return {
                ...state,
                fundingAccounts: merge({}, state.fundingAccounts, action.payload.data),
            };

        case DataActionType.DATA_CREATE_UPDATE_RECIPIENTS:
            return {
                ...state,
                recipients: merge({}, state.recipients, action.payload.data),
            };

        case DataActionType.DATA_CREATE_UPDATE_PAYABLE_RECIPIENTS:
            return {
                ...state,
                payableRecipients: merge({}, state.payableRecipients, action.payload.data),
            };

        case DataActionType.DATA_CREATE_UPDATE_STATES:
            return {
                ...state,
                stateDropdownOptions: merge({}, state.stateDropdownOptions, action.payload.data),
            };

        default:
            return state;
    }
};

export default dataReducer;
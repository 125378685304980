import { CommandBarButton, IconButton, Image, INavLink, INavLinkGroup, Nav, Stack } from "@fluentui/react";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import React from "react";

import Auth from "auth/auth";
import ConstantUtils from "utils/constantUtils";
import IAppState from "duck/interfaces/IAppState";
import IToggleHamburgerMenuAction from "chrome/hamburgerMenu/duck/actions/interfaces/IToggleHamburgerMenuAction";
import LegalEntityType from "content/common/enums/legalEntityType";
import toggleHamburgerMenu from "chrome/hamburgerMenu/duck/actions/toggleHamburgerMenu";

import { regular13 } from "common/style/textVariants.styles";
import hamburgerMenuStyles from "chrome/hamburgerMenu/hamburgerMenu.styles";

const navLinkGroups: INavLinkGroup[] = [
    {
        links: [
            {
                name: "Payables",
                url: "",
                key: ConstantUtils.root,
                icon: "Money",
            },
            {
                name: "Recipients",
                url: "",
                key: ConstantUtils.recipients,
                icon: "People"
            },
            {
                name: "Wallet",
                url: "",
                key: ConstantUtils.wallets,
                icon: "Album"
            },
        ],
    },
];

export default function HamburgerMenu(): JSX.Element {
    const [selectedKey, selectKey] = React.useState(ConstantUtils.root);
    const { isHamburgerMenuVisible } = useSelector((state: IAppState) => state.chromeState.hamburgerMenuState);
    const dispatch: Dispatch<IToggleHamburgerMenuAction> = useDispatch<Dispatch<IToggleHamburgerMenuAction>>();
    const { push } = useHistory();
    const { pathname } = useLocation();
    const menuStyles = hamburgerMenuStyles.componentStyles(isHamburgerMenuVisible);
    const claims = Auth.getClaims();

    React.useEffect((): void => {
        let path = pathname;
        if (path === ConstantUtils.payablesNew || path.search("/payables") !== -1) {
            path = ConstantUtils.root;
        } else if (path.search(ConstantUtils.recipients) !== -1) {
            path = ConstantUtils.recipients;
        }

        selectKey(path);

    }, [pathname]);

    const handleLinkClick = React.useCallback((ev?: React.SyntheticEvent<HTMLElement>, item?: INavLink): void => {
        if (item && item.key) {
            if (pathname !== item.key) {
                push(item.key);
            }
        }
    }, [pathname, push]);

    const handleSettingsClick = React.useCallback((): void => {
        push(ConstantUtils.settings);
    }, [push]);

    return (
        <Stack verticalFill className={`${menuStyles.root}`}>
            <div className={menuStyles.header}>
                <Stack horizontal verticalAlign="center" className={menuStyles.touchable}>
                    <Image
                        className={menuStyles.touchableImage}
                        src="/assets/images/common/simplypay-logo.svg"
                        alt="simplypay logo"
                    />
                    <span className={`${menuStyles.touchableText} ${regular13}`}>
                        {
                            claims?.membership_type === LegalEntityType.Individual
                                ? "Personal"
                                : claims?.membership_name
                        }
                    </span>
                </Stack>
            </div>
            <Stack grow className={menuStyles.navWrapper}>
                <Stack.Item grow>
                    <Nav
                        onLinkClick={handleLinkClick}
                        selectedKey={selectedKey}
                        ariaLabel="HamburgerMenu"
                        groups={navLinkGroups}
                        styles={hamburgerMenuStyles.getNavStyles}
                    />
                </Stack.Item>
                <Stack className={menuStyles.farBtn} horizontal={isHamburgerMenuVisible}>
                    <Stack.Item grow>
                        <CommandBarButton
                            text={isHamburgerMenuVisible ? "Settings" : ""}
                            iconProps={{ iconName: "Settings" }}
                            styles={hamburgerMenuStyles.settingsButtonStyles(selectedKey === ConstantUtils.settings
                                ? true
                                : false)}
                            onClick={handleSettingsClick}
                        />
                    </Stack.Item>
                    <IconButton
                        iconProps={{ iconName: "DoubleChevronLeft" }}
                        onClick={() => dispatch(toggleHamburgerMenu())}
                        styles={hamburgerMenuStyles.getDrawerButtonStyles(isHamburgerMenuVisible)}
                    />
                </Stack>
            </Stack>
        </Stack >
    );
}


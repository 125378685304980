import LegalEntityType from "content/common/enums/legalEntityType";

export default class ConstantUtils {
    // MSAL client id
    static readonly msalClientId = (process.env.REACT_APP_AZUREB2C_TENANT_ID as string);

    // Canada post API key
    static readonly canadaPostAPIKey = (process.env.REACT_APP_CANADA_POST_API_KEY as string);

    // Google places API key
    static readonly googlePlacesAPIKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;

    // API routes
    static readonly PAYABLE_BASE_URL = process.env.REACT_APP_PAYABLE_BASE_URL;
    static readonly API_BASE_URL = "";

    static readonly parseIntRadix = 10;

    static readonly pageSize = 10;

    static readonly calloutGapSpace = 6;

    static readonly customPanelWidth = "938px";

    // Routes constants
    static readonly root = "/";
    static readonly registrationPersonal = `${ConstantUtils.root}${LegalEntityType.Individual}`;
    static readonly registrationPersonalOnboarding = `${ConstantUtils.registrationPersonal}/onboarding`;
    static readonly payablesNew = "/new-payable";
    static readonly payablesDetails = "/payables/:payableId";
    static readonly recipients = "/recipients";
    static readonly recipientDetails = `${ConstantUtils.recipients}/:recipientId`;
    static readonly settings = "/settings";
    static readonly wallets = "/wallets";

    // Currency dropdown field key
    static currencyDropdownFieldKey = "currencyDropdownFieldKey";

    // Async reducers keys
    static readonly recpientsInfoFormState = "recpientsInfoFormState";
    static readonly bankFormState = "bankFormState";
    static readonly addBankAccountState = "addBankAccountState";
    static readonly payableTransactionState = "payableTransactionState";
    static readonly payableFundingAccountState = "payableFundingAccountState";

    // Pivot header button props keys
    static readonly dataStepCompleted = "data-step-completed";

    // Currency and Country key values
    static readonly currencyCountries = {
        "USD": "USA",
        "CAD": "Canada",
        "EUR": "Euro",
        "AUD": "Australia",
        "GBP": "UK",
    };
}

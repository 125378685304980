import { IStyle, mergeStyleSets } from "@fluentui/react";

interface IErroBoundaryStyles {
    root: IStyle;
}

const errorBoundaryStyles = mergeStyleSets<IErroBoundaryStyles>({
    root: {
        padding: "2em",
        color: "red",
    }
});

export default errorBoundaryStyles;
import { Reducer } from "redux";

import Entities from "content/common/enums/entities";
import IListPageAppendMoreDataAction from "common/components/listPage/duck/actions/interfaces/IListPageAppendMoreDataAction";
import IListPageDisplayErrorAction from "common/components/listPage/duck/actions/interfaces/IListPageDisplayErrorAction";
import IListPageLoadDataAction from "common/components/listPage/duck/actions/interfaces/IListPageLoadDataAction";
import IListPageState from "common/components/listPage/duck/interfaces/IListPageState";
import ListPageActions from "common/components/listPage/duck/actions/types/listPageActions";
import ListPageActionType from "common/components/listPage/duck/actionTypes/listPageActionType";

const defaultState: IListPageState = {
    isLoading: false,
    isFetching: false,
    items: [],
    errorMessage: "",
};

export default function listPageReducer(entity: Entities): Reducer<IListPageState, ListPageActions> {
    return (state: IListPageState = defaultState, action: ListPageActions): IListPageState => {
        switch (action.type) {
            case `${entity}_${ListPageActionType.LIST_PAGE_REQUEST_DATA}`:
                return {
                    ...state,
                    isLoading: true,
                    errorMessage: "",
                };

            case `${entity}_${ListPageActionType.LIST_PAGE_LOAD_DATA}`:
                return {
                    ...state,
                    isLoading: false,
                    items: (action as IListPageLoadDataAction).payload.items,
                };

            case `${entity}_${ListPageActionType.LIST_PAGE_FETCH_MORE_DATA}`:
                return {
                    ...state,
                    isFetching: true,
                    errorMessage: "",
                };

            case `${entity}_${ListPageActionType.LIST_PAGE_APPEND_MORE_DATA}`:
                return {
                    ...state,
                    // On initial request for list if the result is an empty array,
                    // it will call the append action and if at that time the isLoading will be true.
                    // By making it false here we can hide the loading spinner and can show message
                    // in component.
                    isLoading: false,
                    isFetching: false,
                    // The filter() is used to remove the previous null item in the array before
                    // appending the latest results to the array.
                    items: state.items
                        ? state.items.concat((action as IListPageAppendMoreDataAction).payload.items)
                        : state.items,
                };

            case `${entity}_${ListPageActionType.LIST_PAGE_DISPLAY_ERROR}`:
                return {
                    ...state,
                    isLoading: false,
                    isFetching: false,
                    errorMessage: (action as IListPageDisplayErrorAction).payload.errorMessage,
                };

            default:
                return state;
        }
    };
}
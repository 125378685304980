import { ICalloutContentStyleProps, ICalloutContentStyles, IStyleFunctionOrObject } from "@fluentui/react";

const calloutStyles: IStyleFunctionOrObject<ICalloutContentStyleProps, ICalloutContentStyles> = {
    container: {
        ".ms-Callout": {
            borderRadius: 12,
            boxShadow: "0px 3px 6px #0000001F",
            marginTop: 4,
            ".ms-Callout-main": {
                borderRadius: "inherit",
            }
        }
    },
    calloutMain: {
        borderRadius: "inherit"
    }
};

export default calloutStyles;
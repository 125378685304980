import HamburgerMenuActionType from "chrome/hamburgerMenu/duck/actionTypes/hamburgerMenuActionType";
import IToggleHamburgerMenuAction from "chrome/hamburgerMenu/duck/actions/interfaces/IToggleHamburgerMenuAction";

type ToggleHamburgerMenu = () => IToggleHamburgerMenuAction;

const toggleHamburgerMenu: ToggleHamburgerMenu = (): IToggleHamburgerMenuAction => ({
    type: HamburgerMenuActionType.TOGGLE_HAMBURGER_MENU,
});

export default toggleHamburgerMenu;

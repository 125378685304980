import { combineReducers } from "redux";

import IPayablesNewPayableDetailsState from "content/payables/payablesNewPayable/payableDetails/duck/interfaces/IPayablesNewPayableDetailsState";
import payablesNewPayableDetailsContentReducer from "content/payables/payablesNewPayable/payableDetails/duck/payablesNewPayableDetailsContentReducer";
import payablesNewPayableDetailsFormReducer from "content/payables/payablesNewPayable/payableDetails/duck/payablesNewPayableDetailsFormReducer";
import payablesNewPayableSelectRecipientReducer from "content/payables/payablesNewPayable/selectRecipient/duck/payablesNewPayableSelectRecipientReducer";

const payablesNewPayableDetailsReducer = combineReducers<IPayablesNewPayableDetailsState>({
    contentState: payablesNewPayableDetailsContentReducer,
    formState: payablesNewPayableDetailsFormReducer,
    recipientsState: payablesNewPayableSelectRecipientReducer,
});

export default payablesNewPayableDetailsReducer;
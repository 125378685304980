import { fontFace } from "@fluentui/react";

export default function registerFonts(): void {
    fontFace({
        fontFamily: "'Graphik-Regular'",
        src: `url("/assets/fonts/graphik-regular/Graphik-Regular.eot"),
              url("/assets/fonts/graphik-regular/Graphik-Regular.eot?#iefix") format("embedded-opentype"),
              url("/assets/fonts/graphik-regular/Graphik-Regular.svg#Graphik-Regular") format("svg"),
              url("/assets/fonts/graphik-regular/Graphik-Regular.ttf") format("truetype"),
              url("/assets/fonts/graphik-regular/Graphik-Regular.woff") format("woff")`,
        fontWeight: "normal",
    });
}

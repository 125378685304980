import { IStackItemProps, IStackItemStyles, ITheme } from "@fluentui/react";

const contentStyles = (shouldHavePadding: boolean) =>
    (props: IStackItemProps, theme: ITheme): IStackItemStyles => ({
        root: {
            overflow: "auto",
            backgroundColor: theme.palette.white,
            padding: shouldHavePadding ? "24px" : 0,
            paddingBottom: 0,
            height: "100%",
        }
    });

export default contentStyles;
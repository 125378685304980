import { PanelType } from "@fluentui/react";
import { Reducer } from "redux";

import IChangePanelSizeAction from "chrome/panel/duck/actions/interfaces/IChangePanelSizeAction";
import IClosePanelAction from "chrome/panel/duck/actions/interfaces/IClosePanelAction";
import IDisplayErrorInPanelAction from "chrome/panel/duck/actions/interfaces/IDisplayErrorInPanelAction";
import IHideSpinnerInPanelAction from "chrome/panel/duck/actions/interfaces/IHideSpinnerInPanelAction";
import IOpenPanelAction from "chrome/panel/duck/actions/interfaces/IOpenPanelAction";
import IPanelState from "chrome/panel/duck/interfaces/IPanelState";
import IShowSpinnerInPanelAction from "chrome/panel/duck/actions/interfaces/IShowSpinnerInPanelAction";
import PanelActionType from "chrome/panel/duck/actionTypes/panelActionType";

type Action = IOpenPanelAction |
    IClosePanelAction |
    IDisplayErrorInPanelAction |
    IShowSpinnerInPanelAction |
    IHideSpinnerInPanelAction |
    IChangePanelSizeAction;

const defaultState: IPanelState = {
    isVisible: false,
    shouldShowSpinner: false,
    data: {
        panelType: PanelType.medium,
        headerText: "",
        content: "",
    },
    errorMessage: "",
};

const panelReducer: Reducer<IPanelState, Action> =
    (state: IPanelState = defaultState, action: Action): IPanelState => {
        switch (action.type) {
            case PanelActionType.OPEN_PANEL:
                return {
                    ...state,
                    isVisible: true,
                    data: action.payload,
                };

            case PanelActionType.CLOSE_PANEL:
                return {
                    ...state,
                    isVisible: false,
                };

            case PanelActionType.DISPLAY_ERROR_IN_PANEL:
                return {
                    ...state,
                    errorMessage: action.payload.errorMessage,
                };

            case PanelActionType.SHOW_SPINNER_IN_PANEL:
                return {
                    ...state,
                    shouldShowSpinner: true,
                };

            case PanelActionType.HIDE_SPINNER_IN_PANEL:
                return {
                    ...state,
                    shouldShowSpinner: false,
                };

            case PanelActionType.CHANGE_PANEL_SIZE:
                return {
                    ...state,
                    data: {
                        ...state.data,
                        panelType: action.payload.panelType,
                        customPanelWidth: action.payload.customPanelWidth,
                    },
                };

            default:
                return state;
        }
    };

export default panelReducer;
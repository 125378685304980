import { Route, RouteProps, Switch } from "react-router-dom";
import { Stack } from "@fluentui/react";
import React from "react";

import ConstantUtils from "utils/constantUtils";
import ErrorBoundary from "common/components/errorBoundary/errorBoundary";

import styles from "content/content.styles";

const Payables = React.lazy(() => import("content/payables/payables"));
const PayableDetails = React.lazy(() => import("content/payableDetails/payableDetails"));
const PayablesNewPayable = React.lazy(() => import("content/payables/payablesNewPayable/payablesNewPayable"));
const Recipients = React.lazy(() => import("content/recipients/recipients"));
const RecipientDetails = React.lazy(() => import("content/recipientDetails/recipientDetails"));
const Settings = React.lazy(() => import("content/settings/settings"));
const Wallets = React.lazy(() => import("content/wallets/wallets"));

const routeList: RouteProps[] = [
    { path: ConstantUtils.root, component: Payables },
    { path: ConstantUtils.payablesDetails, component: PayableDetails },
    { path: ConstantUtils.payablesNew, component: PayablesNewPayable },
    { path: ConstantUtils.recipients, component: Recipients },
    { path: ConstantUtils.recipientDetails, component: RecipientDetails },
    { path: ConstantUtils.settings, component: Settings },
    { path: ConstantUtils.wallets, component: Wallets }

];

export default function Content(): JSX.Element {
    return (
        <Stack.Item grow styles={styles(true)}>
            <React.Suspense fallback={
                // Todo: Need to replace with appropriate shimmer or spinner.
                <div>Loading...</div>
            }>
                <ErrorBoundary>
                    <Switch>
                        {routeList.map((routeProps: RouteProps, i: number) =>
                            <Route
                                key={i}
                                {...routeProps}
                                exact={routeProps.exact === false ? false : true}
                            />)
                        }
                    </Switch>
                </ErrorBoundary>
            </React.Suspense>
        </Stack.Item>
    );
}
import { Reducer } from "redux";

import HamburgerMenuActionType from "chrome/hamburgerMenu/duck/actionTypes/hamburgerMenuActionType";
import IHamburgerMenuState from "chrome/hamburgerMenu/duck/interfaces/IHamburgerMenuState";
import IToggleHamburgerMenuAction from "chrome/hamburgerMenu/duck/actions/interfaces/IToggleHamburgerMenuAction";

type Action = IToggleHamburgerMenuAction;

const hamburgerMenuDefaultState: IHamburgerMenuState = {
    isHamburgerMenuVisible: true,
};

const hamburgerMenuReducer: Reducer<IHamburgerMenuState, Action> =
    (state: IHamburgerMenuState = hamburgerMenuDefaultState, action: Action): IHamburgerMenuState => {
        switch (action.type) {
            case HamburgerMenuActionType.TOGGLE_HAMBURGER_MENU:
                return {
                    ...state,
                    isHamburgerMenuVisible: !state.isHamburgerMenuVisible
                };

            default:
                return state;
        }
    };

export default hamburgerMenuReducer;

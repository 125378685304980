import { IMessageBarStyleProps, IMessageBarStyles, IStyleFunctionOrObject } from "@fluentui/react";

import { regular14 } from "common/style/textVariants.styles";

const errorMessageBarStyles =
    (applyMarginAtBottom?: boolean): IStyleFunctionOrObject<IMessageBarStyleProps, IMessageBarStyles> =>
        (props: IMessageBarStyleProps) => ({
            root: {
                borderRadius: 12,
                backgroundColor: "#FFEFEF",
                padding: "2px 3px",
                marginBottom: applyMarginAtBottom ? "20px" : 0
            },
            innerText: regular14,
            icon: {
                color: props.theme.semanticColors.errorText,
                lineHeight: 21,
            },
            text: {
                marginLeft: 15,
            },
            dismissal: {
                ".ms-Icon": {
                    fontSize: 14,
                }
            }
        });

export default errorMessageBarStyles;
import { combineReducers, Reducer } from "redux";

import chromeContentReducer from "chrome/duck/chromeContentReducer";
import dialogBoxReducer from "chrome/dialogBox/duck/dialogBoxReducer";
import hamburgerMenuReducer from "chrome/hamburgerMenu/duck/hamburgerMenuReducer";
import IChromeState from "chrome/duck/interfaces/IChromeState";
import panelReducer from "chrome/panel/duck/panelReducer";

const chromeReducer: Reducer<IChromeState> = combineReducers<IChromeState>({
    contentState: chromeContentReducer,
    hamburgerMenuState: hamburgerMenuReducer,
    dialogBoxState: dialogBoxReducer,
    panelState: panelReducer,
});

export default chromeReducer;

import { Reducer } from "redux";

import IPayablesNewPayableDetailsDisplayErrorAction from "content/payables/payablesNewPayable/payableDetails/duck/actions/interfaces/IPayablesNewPayableDetailsDisplayErrorAction";
import IPayablesNewPayableDetailsIsDateChangedAction from "content/payables/payablesNewPayable/payableDetails/duck/actions/interfaces/IPayablesNewPayableDetailsIsDateChangedAction";
import IPayablesNewPayableDetailsLoadCurrenciesAction from "content/payables/payablesNewPayable/payableDetails/duck/actions/interfaces/IPayablesNewPayableDetailsLoadCurrenciesAction";
import IPayablesNewPayableDetailsLoadExchangeRateAction from "content/payables/payablesNewPayable/payableDetails/duck/actions/interfaces/IPayablesNewPayableDetailsLoadExchangeRateAction";
import IPayablesNewPayableDetailsRequestDataAction from "content/payables/payablesNewPayable/payableDetails/duck/actions/interfaces/IPayablesNewPayableDetailsRequestDataAction";
import IPayablesNewPayableDetailsSelectCurrencyAction from "content/payables/payablesNewPayable/payableDetails/duck/actions/interfaces/IPayablesNewPayableDetailsSelectCurrencyAction";
import IPayablesNewPayableDetailsSelectRecipientAction from "content/payables/payablesNewPayable/payableDetails/duck/actions/interfaces/IPayablesNewPayableDetailsSelectRecipientAction";
import IPayablesNewPayableDetailsSelectSenderCurrencyAction from "content/payables/payablesNewPayable/payableDetails/duck/actions/interfaces/IPayablesNewPayableDetailsSelectSenderCurrencyAction";
import PayablesNewPayableDetailsActionType from "content/payables/payablesNewPayable/payableDetails/duck/actionTypes/payablesNewPayableDetailsActionType";
import State from "content/payables/payablesNewPayable/payableDetails/duck/interfaces/IPayablesNewPayableDetailsContentState";

type Action = IPayablesNewPayableDetailsSelectRecipientAction |
    IPayablesNewPayableDetailsDisplayErrorAction |
    IPayablesNewPayableDetailsLoadExchangeRateAction |
    IPayablesNewPayableDetailsLoadCurrenciesAction |
    IPayablesNewPayableDetailsRequestDataAction |
    IPayablesNewPayableDetailsSelectCurrencyAction |
    IPayablesNewPayableDetailsIsDateChangedAction |
    IPayablesNewPayableDetailsSelectSenderCurrencyAction;

const defaultState: State = {
    recipient: "",
    errorMessage: "",
    exchangeRate: undefined,
    isLoading: false,
    currencies: [],
    selectedSenderCurrency: undefined,
    selectedCurrency: undefined,
    isDateChanged: false,
};

const payablesNewPayableDetailsContentReducer: Reducer<State, Action> =
    (state: State = defaultState, action: Action): State => {
        switch (action.type) {
            case PayablesNewPayableDetailsActionType.PAYABLES_NEW_PAYABLE_DETAILS_SELECT_RECIPIENT:
                return {
                    ...state,
                    recipient: action.payload.recipient,
                };

            case PayablesNewPayableDetailsActionType.PAYABLES_NEW_PAYABLE_DETAILS_DISPLAY_ERROR:
                return {
                    ...state,
                    isLoading: false,
                    errorMessage: action.payload.errorMessage,
                };

            case PayablesNewPayableDetailsActionType.PAYABLES_NEW_PAYABLE_DETAILS_REQUEST_DATA:
                return {
                    ...state,
                    isLoading: true,
                    errorMessage: "",
                };

            case PayablesNewPayableDetailsActionType.PAYABLES_NEW_PAYABLE_DETAILS_LOAD_EXCHANGE_RATE:
                return {
                    ...state,
                    isLoading: false,
                    exchangeRate: action.payload.exchangeRate,
                };

            case PayablesNewPayableDetailsActionType.PAYABLES_NEW_PAYABLE_DETAILS_LOAD_CURRENCIES:
                return {
                    ...state,
                    isLoading: false,
                    currencies: action.payload.currencies,
                };

            case PayablesNewPayableDetailsActionType.PAYABLES_NEW_PAYABLE_DETAILS_SELECT_CURRENCY:
                return {
                    ...state,
                    selectedCurrency: action.payload.currency,
                };

            case PayablesNewPayableDetailsActionType.PAYABLES_NEW_PAYABLE_DETAILS_SELECT_SENDER_CURRENCY:
                return {
                    ...state,
                    selectedSenderCurrency: action.payload.currency,
                };
                
            case PayablesNewPayableDetailsActionType.PAYABLES_NEW_PAYABLE_DETAILS_IS_DATE_CHANGED:
                return {
                    ...state,
                    isLoading: false,
                    isDateChanged: action.payload,
                };

            default:
                return state;
        }
    };

export default payablesNewPayableDetailsContentReducer;
enum PayablesNewPayableFormFieldNames {
    // Domestic fields
    paymentDate = "payables_new_payable_paymentDate",
    amount = "payables_new_payable_amount",
    statementDescriptor = "payables_new_payable_statementDescriptor",

    // International fields
    youSend = "payables_new_payable_youSend",
    recipientGets = "payables_new_payable_recipientGets",
    recipentCurrency = "payables_new_payable_recipientCurrency",

    // Common fields
    paymentPurpose = "payables_new_payable_paymentPurpose",
}

export default PayablesNewPayableFormFieldNames;
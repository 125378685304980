import { combineReducers } from "redux";

import IRegistrationState from "content/registration/duck/interfaces/IRegistrationState";
import onboardingReducer from "content/onboarding/duck/onboardingReducer";
import registrationPersonalReducer from "content/registration/personal/duck/registrationPersonalReducer";

const registrationReducer = combineReducers<IRegistrationState>({
    personalState: registrationPersonalReducer,
    onboardingState: onboardingReducer,
});

export default registrationReducer;
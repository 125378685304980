import { Reducer } from "redux";

import Action from "content/wallets/duck/actions/types/walletActions";
import State from "content/wallets/duck/interfaces/IWalletsState";
import WalletsActionType from "content/wallets/duck/actionTypes/WalletsActionType";

const defaultState: State = {
    wallets: [],
    walletCurrencies: [],
    isLoading: false,
    errorMessage: ""
};

const walletsReducer: Reducer<State, Action> = (state = defaultState, action: Action): State => {
    switch (action.type) {
        case WalletsActionType.WALLETS_REQUEST_DATA:
            return {
                ...state,
                isLoading: true,
                errorMessage: ""
            };

        case WalletsActionType.WALLETS_LOAD_WALLETS_DATA:
            return {
                ...state,
                wallets: action.payload.data,
                isLoading: false,
                errorMessage: ""
            };

        case WalletsActionType.WALLETS_LOAD_CURRENCY_DATA:
            return {
                ...state,
                walletCurrencies: action.payload.data,
                isLoading: false,
                errorMessage: ""
            };

        case WalletsActionType.WALLETS_DISPLAY_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload.errorMessage
            };

        default:
            return state;
    }
};

export default walletsReducer;
import { IDialogContentStyleProps, IDialogContentStyles, IDialogFooterStyleProps, IDialogFooterStyles, IDialogStyleProps, IDialogStyles, IStackStyles, IStyleFunctionOrObject, mergeStyles } from "@fluentui/react";

import { medium24, regular14 } from "common/style/textVariants.styles";

const spinnerStack: IStackStyles = {
    root: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
    }
};

const errorMessage = mergeStyles({
    fontSize: 14,
    fontWeight: 400,
    color: "rgb(168, 0, 0)",
    width: "100%",
    marginTop: 30,
});

const dialog: IStyleFunctionOrObject<IDialogStyleProps, IDialogStyles> = {
    main: {
        maxWidth: 530,
        width: "100%",
        margin: "0 15px",
        boxShadow: "0px 3px 6px #00000029",
        "@media(min-width: 480px)": {
            maxWidth: 530,
            width: "100%",
        },
    }
};

const dialogContent: IStyleFunctionOrObject<IDialogContentStyleProps, IDialogContentStyles> =
    (props: IDialogContentStyleProps) => ({
        title: [medium24, {
            lineHeight: "normal",
            padding: "30px 50px 27px 30px",
        }],
        topButton: {
            padding: "23px 15px 0px 0px",
        },
        // Close button
        button: {
            "i": {
                color: props.theme.palette.neutralPrimary,
                fontSize: 14,
            }
        },
        inner: {
            padding: "0px 30px 30px"
        },
        innerContent: regular14,
    });

const footer: IStyleFunctionOrObject<IDialogFooterStyleProps, IDialogFooterStyles> = {
    actions: { lineHeight: "normal" },
};

const dialogBoxStyles = {
    errorMessage,
    dialog,
    dialogContent,
    spinnerStack,
    footer,
};

export default dialogBoxStyles;
import DialogBoxActionType from "chrome/dialogBox/duck/actionTypes/dialogBoxActionType";
import IError from "common/types/IError";
import IShowErrorInDialogBoxAction from "chrome/dialogBox/duck/actions/interfaces/IShowErrorInDialogBoxAction";

type ShowErrorInDialogBox = (payload: IError) => IShowErrorInDialogBoxAction;

const showErrorInDialogBox: ShowErrorInDialogBox =
    (payload: IError): IShowErrorInDialogBoxAction => ({
        type: DialogBoxActionType.SHOW_ERROR_IN_DIALOG_BOX,
        payload,
    });

export default showErrorInDialogBox;

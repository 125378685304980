import { combineReducers } from "redux";

import fundingAccountInstantVerificationReducer from "content/common/fundingAccount/paneInstantVerificationWrapper/duck/fundingAccountInstantVerificationReducer";
import fundingAccountManualVerificationReducer from "content/common/fundingAccount/panelManualVerificationWrapper/duck/fundingAccountManualVerificationReducer";
import FundingAccountSelectVerificationTypeActionType from "content/common/fundingAccount/panelSelectVerificationType/duck/actionTypes/fundingAccountSelectVerificationTypeActionType";
import IFundingAccountSelectVerificationTypeAction from "content/common/fundingAccount/panelSelectVerificationType/duck/actions/interfaces/IFundingAccountSelectVerificationTypeAction";
import IFundingAccountSelectVerificationTypeSelectionState from "content/common/fundingAccount/panelSelectVerificationType/duck/interfaces/IFundingAccountSelectVerificationTypeSelectionState";
import IFundingAccountSelectVerificationTypeState from "content/common/fundingAccount/panelSelectVerificationType/duck/interfaces/IFundingAccountSelectVerificationTypeState";

type State = IFundingAccountSelectVerificationTypeSelectionState;
type Action = IFundingAccountSelectVerificationTypeAction;

const defaultState: State = {
    selectedType: null,
};

function fundingAccountSelectVerificationTypeSelectionReducer(state: State = defaultState, action: Action): State {
    switch (action.type) {
        case FundingAccountSelectVerificationTypeActionType.FUNDING_ACCOUNT_SELECT_VERIFICATION_TYPE:
            return {
                selectedType: action.payload.cardType,
            };

        default:
            return state;
    }
}

const fundingAccountSelectVerificationTypeReducer = combineReducers<IFundingAccountSelectVerificationTypeState>({
    selectionState: fundingAccountSelectVerificationTypeSelectionReducer,
    instantVerificationState: fundingAccountInstantVerificationReducer,
    manualVerificationState: fundingAccountManualVerificationReducer
});

export default fundingAccountSelectVerificationTypeReducer;
import { AccountInfo, AuthError, EventType, InteractionType, IPublicClientApplication, PublicClientApplication, SilentRequest } from "@azure/msal-browser";

import { forgotPasswordRequest, loginRequest, msalConfig } from "auth/authConfig";
import authConstants from "auth/authConstants";
import IAuthIdTokenClaims from "auth/interfaces/IAuthIdTokenClaims";
import Policies from "auth/policies";
import StringOrNull from "common/types/stringOrNull";

interface IEvent {
    eventType: EventType;
    error: AuthError;
    interactionType: InteractionType;
    payload: {
        idTokenClaims: IAuthIdTokenClaims;
        account: AccountInfo;
    };
}

export default class Auth {
    static readonly instance: IPublicClientApplication = new PublicClientApplication(msalConfig);

    static getEventCallback(): StringOrNull {
        return Auth.instance.addEventCallback((event: IEvent): void => {
            switch (event.eventType) {
                case EventType.LOGIN_FAILURE:
                    if (event.error) {
                        if (event.error.errorMessage.indexOf(authConstants.forgotPasswordCode) > -1) {
                            if (event.interactionType === InteractionType.Redirect) {
                                void Auth.instance.loginRedirect(forgotPasswordRequest);
                            } else if (event.interactionType === InteractionType.Popup) {
                                void Auth.instance.loginPopup(forgotPasswordRequest);
                            }
                        } else if (event.error.errorMessage.search(authConstants.userCancellationCode) > -1) {
                            // On cancelling anything on the authentication pages coming from the
                            // azure portal, we just redirecting user to sign in page.
                            void Auth.instance.loginRedirect(loginRequest);
                        }
                    }
                    break;

                case EventType.LOGIN_SUCCESS:
                    if (event.payload.idTokenClaims.acr === Policies.forgotPassword.toLowerCase()) {
                        // Tokens returned from password reset policy cannot be used for sign-in policy, 
                        // must log out then sign back in
                        void Auth.instance.logout({ account: event.payload.account });
                    }

                    const account = this.getAccount();
                    if (account?.idTokenClaims) {
                        this.setClaims(account.idTokenClaims as IAuthIdTokenClaims);
                    }
                    break;

                case EventType.ACQUIRE_TOKEN_FAILURE:
                    if (event.error.errorCode === authConstants.ssoSilentCode) {
                        void Auth.instance.logout();
                    }
                    break;

                default:
                    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
                        // Note: Putting this log here to get the actual event object when MSAL failed at any case; 
                        //       so we can handle those cases based on eventType!
                        console.log("EVENT::", event);
                    }
            }
        });
    }

    static async acquireAccessToken(): Promise<string> {
        // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
        const activeAccount = Auth.instance.getActiveAccount();
        const accounts = Auth.instance.getAllAccounts();

        const request: SilentRequest = {
            scopes: authConstants.scopes,
            account: activeAccount || accounts[0],
            redirectUri: `${window.location.origin}/auth.html`,
        };

        const result = await Auth.instance.acquireTokenSilent(request);

        return `${result.tokenType} ${result.accessToken}`;
    }

    static getAccount(): AccountInfo | null {
        const activeAccount = Auth.instance.getActiveAccount();

        if (activeAccount) {
            return activeAccount;
        }

        const accounts = Auth.instance.getAllAccounts();

        return accounts[0];
    }

    static setClaims(claims: IAuthIdTokenClaims): void {
        sessionStorage.setItem(authConstants.claims, JSON.stringify(claims));
    }

    static getClaims(): IAuthIdTokenClaims | undefined {
        const claims = sessionStorage.getItem(authConstants.claims);

        return claims && JSON.parse(claims);
    }
}
enum PayablesNewPayableDetailsActionType {
    PAYABLES_NEW_PAYABLE_DETAILS_SELECT_RECIPIENT = "PAYABLES_NEW_PAYABLE_DETAILS_SELECT_RECIPIENT",
    PAYABLES_NEW_PAYABLE_DETAILS_DISPLAY_ERROR = "PAYABLES_NEW_PAYABLE_DETAILS_DISPLAY_ERROR",
    PAYABLES_NEW_PAYABLE_DETAILS_REQUEST_DATA = "PAYABLES_NEW_PAYABLE_DETAILS_REQUEST_DATA",
    PAYABLES_NEW_PAYABLE_DETAILS_IS_DATE_CHANGED = "PAYABLES_NEW_PAYABLE_DETAILS_IS_DATE_CHANGED",
    PAYABLES_NEW_PAYABLE_DETAILS_LOAD_EXCHANGE_RATE = "PAYABLES_NEW_PAYABLE_DETAILS_LOAD_EXCHANGE_RATE",
    PAYABLES_NEW_PAYABLE_DETAILS_LOAD_CURRENCIES = "PAYABLES_NEW_PAYABLE_DETAILS_LOAD_CURRENCIES",
    PAYABLES_NEW_PAYABLE_DETAILS_SELECT_CURRENCY = "PAYABLES_NEW_PAYABLE_DETAILS_SELECT_CURRENCY",
    PAYABLES_NEW_PAYABLE_DETAILS_SELECT_SENDER_CURRENCY = "PAYABLES_NEW_PAYABLE_DETAILS_SELECT_SENDER_CURRENCY",
}

export default PayablesNewPayableDetailsActionType;
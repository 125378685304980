export default class Policies {
    static readonly forgotPassword = "B2C_1A_PasswordReset";
    static readonly changePassword = "B2C_1A_PASSWORDCHANGE";
    private static readonly domain = (process.env.REACT_APP_AZUREB2C_DOMAIN as string);
    private static readonly tenant = (process.env.REACT_APP_AZUREB2C_TENANT as string);
    private static readonly baseAuthority = `https://${Policies.tenant}/tfp/${Policies.domain}/`;
    private static readonly signUpSignIn = "B2C_1A_SignUp_SignIn";

    static get signUpSignInAuthority(): string {
        return `${this.baseAuthority}${this.signUpSignIn}`;
    }

    static get forgotPasswordAuthority(): string {
        return `${this.baseAuthority}${this.forgotPassword}`;
    }

    static get changePasswordAuthority(): string {
        return `${this.baseAuthority}${this.changePassword}`;
    }
}
import { IContextualMenuItemStyleProps, IContextualMenuItemStyles, IContextualMenuStyleProps, IContextualMenuStyles, IStyleFunctionOrObject } from "@fluentui/react";

import simplypayTheme from "simplypayTheme";

import { regular13 } from "common/style/textVariants.styles";

const menu: IStyleFunctionOrObject<IContextualMenuStyleProps, IContextualMenuStyles> = {
    list: {
        padding: "8px 0",
    },
};

const menuItem: IStyleFunctionOrObject<IContextualMenuItemStyleProps, IContextualMenuItemStyles> = {
    item: {
        "&.active .ms-ContextualMenu-link": {
            backgroundColor: simplypayTheme.semanticColors.menuItemBackgroundHovered,
            color: simplypayTheme.palette.neutralPrimary,
        }
    },
    root: {
        padding: "0 11px",
    },
    icon: {
        color: simplypayTheme.palette.neutralPrimary,
        width: "auto",
    },
    label: {
        flexGrow: "auto",
    },
    secondaryText: [regular13, {
        color: simplypayTheme.semanticColors.inputIcon,
        textAlign: "left",
        paddingLeft: 6,
    }],
};

const ecbMenuStyles = {
    menu,
    menuItem,
};

export default ecbMenuStyles;
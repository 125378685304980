enum DataActionType {
    // Action for onboarding documents
    DATA_CREATE_UPDATE_ONBOARDING_FILES = "DATA_CREATE_UPDATE_ONBOARDING_FILES",

    // Actions for Payables
    DATA_CREATE_UPDATE_PAYABLES = "DATA_CREATE_UPDATE_PAYABLES",
    DATA_CREATE_UPDATE_PAYABLE_RECIPIENTS = "DATA_CREATE_UPDATE_PAYABLE_RECIPIENTS",

    // Actions for accounts
    DATA_CREATE_UPDATE_ACCOUNTS = "DATA_CREATE_UPDATE_ACCOUNTS",

    // Actions for Recipients
    DATA_CREATE_UPDATE_RECIPIENTS = "DATA_CREATE_UPDATE_RECIPIENTS",

    // Actions for States
    DATA_CREATE_UPDATE_STATES = "DATA_CREATE_UPDATE_STATES",
}

export default DataActionType;
import Action from "content/payables/payablesNewPayable/reviewPayable/duck/actions/types/payablesNewPayableReviewPayableActions";
import PayablesNewPayableReviewPayableActionType from "content/payables/payablesNewPayable/reviewPayable/duck/actionTypes/payablesNewPayableReviewPayableActionType";
import State from "content/payables/payablesNewPayable/reviewPayable/duck/interfaces/IPayablesNewPayableReviewPayableState";

const defaultState: State = {
    isLoading: false,
    errorMessage: "",
    paymentOrder: "",
    quote: null,
};

export default function payablesNewPayableReviewPayableReducer(state = defaultState, action: Action): State {
    switch (action.type) {
        case PayablesNewPayableReviewPayableActionType.PAYABLES_NEW_PAYABLE_REVIEW_PAYABLE_REQUEST_DATA:
            return {
                ...state,
                isLoading: true,
            };

        case PayablesNewPayableReviewPayableActionType.PAYABLES_NEW_PAYABLE_REVIEW_PAYABLE_LOAD_DATA:
            return {
                ...state,
                isLoading: false,
                paymentOrder: action.payload.paymentOrder,
            };

        case PayablesNewPayableReviewPayableActionType.PAYABLES_NEW_PAYABLE_REVIEW_PAYABLE_DISPLAY_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload.errorMessage,
            };

        case PayablesNewPayableReviewPayableActionType.PAYABLES_NEW_PAYABLE_REVIEW_PAYABLE_LOAD_QUOTE:
            return {
                ...state,
                isLoading: false,
                quote: action.payload.quoteDetails,
            };

        default:
            return state;
    }
}
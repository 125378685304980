import { IOverlayStyleProps, IOverlayStyles, IStyleFunctionOrObject, mergeStyles } from "@fluentui/react";
import flexbox from "common/style/flexbox/flexbox.styles";

import simplypayTheme from "simplypayTheme";

const overlay: IStyleFunctionOrObject<IOverlayStyleProps, IOverlayStyles> = {
    root: {
        ...flexbox({ vAlign: "center", hAlign: "center" }),
        zIndex: 1,
    }
};

const spinnerWrapper = mergeStyles({
    padding: 20,
    borderRadius: 12,
    backgroundColor: simplypayTheme.semanticColors.menuBackground,
});

const spinnerStyles = {
    overlay,
    spinnerWrapper,
};

export default spinnerStyles;
import Action from "common/components/panelUploadFiles/duck/actions/types/panelUploadFilesActions";
import PanelUploadFilesActionType from "common/components/panelUploadFiles/duck/actionTypes/panelUploadFilesActionType";
import State from "common/components/panelUploadFiles/duck/interfaces/IPanelUploadFilesState";

const defaultState: State = {
    isLoading: false,
    files: [],
};

const panelUploadFilesReducer = (state: State = defaultState, action: Action): State => {
    switch (action.type) {
        case PanelUploadFilesActionType.PANEL_UPLOAD_FILES_REQUEST_DATA:
            return {
                ...state,
                isLoading: true,
            };

        case PanelUploadFilesActionType.PANEL_UPLOAD_FILES_LOAD_DATA:
            return {
                ...state,
                isLoading: false,
                files: state.files && state.files.length
                    ? action.payload.files.concat(state.files)
                    : action.payload.files,
            };

        case PanelUploadFilesActionType.PANEL_UPLOAD_FILES_HIDE_SPINNER:
            return {
                ...state,
                isLoading: false,
            };

        default:
            return state;
    }
};

export default panelUploadFilesReducer;
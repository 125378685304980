import { IButtonStyles, INavStyleProps, INavStyles, IProcessedStyleSet, IRawStyle, IStyle, IStyleFunction, mergeStyleSets } from "@fluentui/react";

import simplypayTheme from "simplypayTheme";

interface IHamburgerMenuStyles {
    root: IStyle;
    header: IStyle;
    touchable: IStyle;
    touchableImage: IStyle;
    touchableText: IStyle;
    navWrapper: IStyle;
    farBtn: IStyle;
}

const borderRadius = 21;
const hoverColor = "#ffffff1f";
const hamburgerMenuWidth = 203;
const closedHamburgerMenuWidth = 60;

const sharedBorderRadius: IStyle = {
    borderTopLeftRadius: borderRadius,
    borderBottomLeftRadius: borderRadius,
};

const sharedImgProperties: IStyle = {
    maxHeight: 26,
    minWidth: 28.75,
};

const getSharedColor = (): IRawStyle => ({
    color: simplypayTheme.semanticColors.menuBackground,
});

const sharedColorAndBackground: IStyle = {
    ...getSharedColor(),
    backgroundColor: "transparent",
};

const sharedButtonStyles: IStyle = {
    ...sharedBorderRadius,
    ...sharedColorAndBackground,
    paddingLeft: 11.5,
    paddingRight: 11.5,
    fontWeight: 400,
    lineHeight: 40,
    height: 40,
    margin: 0,
};

const sharedSettingsBtnStyles: IStyle = {
    ...getSharedColor(),
    ...sharedBorderRadius,
    backgroundColor: hoverColor,
};

const componentStyles = (isHamburgerMenuVisible: boolean): IProcessedStyleSet<IHamburgerMenuStyles> =>
    mergeStyleSets<IHamburgerMenuStyles>({
        root: {
            width: isHamburgerMenuVisible ? hamburgerMenuWidth : closedHamburgerMenuWidth,
            backgroundImage: "url('/assets/images/hamburgerMenu/background.svg')",
            backgroundSize: "cover",
            transition: ".3s",
        },
        header: {
            padding: "0 7px",
            marginTop: 6,
        },
        touchable: {
            width: 189,
            minHeight: 50,
            padding: "3px 9px",
            cursor: "pointer",
            overflow: "hidden",
            ":hover": {
                backgroundColor: hoverColor,
                borderRadius: 25,
            },
            "> :not(:first-child)": {
                marginLeft: 15,
            }
        },
        touchableImage: {
            ...sharedImgProperties,
            "& img": sharedImgProperties,
        },
        touchableText: {
            ...getSharedColor(),
            overflow: "hidden",
        },
        navWrapper: {
            paddingTop: 77,
            paddingLeft: 15,
            overflowX: "hidden",
            overflowY: "auto",
        },
        farBtn: {
            marginBottom: 10,
            transition: "0.7s",
            ...!isHamburgerMenuVisible && {
                "> :not(:first-child)": {
                    marginTop: 12,
                }
            }
        },
    });

const getNavStyles: IStyleFunction<INavStyleProps, INavStyles> = (props: INavStyleProps) => ({
    navItem: {
        marginBottom: 10,
    },
    compositeLink: {
        "&.ms-Nav-compositeLink:hover .ms-Nav-link": {
            backgroundColor: hoverColor,
            ...getSharedColor(),
        }
    },
    link: {
        ...sharedButtonStyles,
        backgroundColor: props.isSelected ? hoverColor : "transparent",
        ":hover .ms-Button-icon": getSharedColor(),
        ":active .ms-Button-icon": getSharedColor(),
        "& .ms-Button-icon": {
            marginRight: 14,
            marginLeft: 0,
            ...getSharedColor(),
        },
        "& .ms-Button-label": {
            margin: 0,
        },
        "::after": {
            display: "none"
        }
    },
    linkText: {
        margin: 0,
    },
});

const settingsButtonStyles: IStyleFunction<boolean, IButtonStyles> = (selected:boolean) => ({
    root: {
        ...sharedButtonStyles,
        marginRight: 0,
        width: "100%",
        backgroundColor: selected ? hoverColor : "transparent",
    },
    rootHovered: sharedSettingsBtnStyles,
    rootPressed: sharedSettingsBtnStyles,
    icon: getSharedColor(),
    iconHovered: getSharedColor(),
    iconPressed: getSharedColor(),
    textContainer: {
        textAlign: "left"
    },
    label: {
        marginLeft: 7,
    }
});

const getDrawerButtonStyles = (isHamburgerMenuVisible: boolean): IButtonStyles => ({
    root: {
        ...getSharedColor(),
        transition: ".5s",
        fontSize: 13,
        width: 14,
        height: "auto",
        ...isHamburgerMenuVisible
            ? {
                transform: "rotate(0deg)",
                padding: "0 15px",
                marginRight: 16,
            }
            : {
                transform: "rotate(-180deg)",
                padding: 0,
                marginRight: 6,
                alignSelf: "center",
            }
    },
    rootHovered: sharedColorAndBackground,
    rootPressed: sharedColorAndBackground,
});

const humburgerMenuStyles = {
    componentStyles,
    getNavStyles,
    settingsButtonStyles,
    getDrawerButtonStyles,
};

export default humburgerMenuStyles;
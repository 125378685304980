import { IButtonStyles } from "@fluentui/react";
import flexbox from "common/style/flexbox/flexbox.styles";

const commonBackgroundColor = "#F0ECFF";
const commonSqureValue = 32;
const commonBorderRadius = "50%";
const transparentColor = "transparent";
const commonIconColor = "#5E3BED";

const userButtonStyles: IButtonStyles = {
    root: {
        backgroundColor: transparentColor,
        padding: 4,
        marginLeft: 4,
        height: commonSqureValue,
    },
    rootHovered: {
        backgroundColor: transparentColor,
    },
    icon: {
        borderRadius: commonBorderRadius,
        backgroundColor: commonBackgroundColor,
        color: commonIconColor,
        width: commonSqureValue,
        height: commonSqureValue,
        marginLeft: 7,
        ...flexbox({ vAlign: "center", hAlign: "center" }),
    },
};

export default userButtonStyles;
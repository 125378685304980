import { Reducer } from "redux";

import IDisplayFieldErrorAction from "common/fields/common/duck/actions/interfaces/IDisplayFieldErrorAction";
import IFieldControlState from "common/fields/common/interfaces/IFieldControlState";
import IResetFieldAction from "common/fields/common/duck/actions/interfaces/IResetFieldAction";
import IUpdateFieldValueAction from "common/fields/common/duck/actions/interfaces/IUpdateFieldValueAction";
import TextFieldActionType from "common/fields/textField/duck/actionTypes/textFieldActionType";

type Action = IDisplayFieldErrorAction | IUpdateFieldValueAction | IResetFieldAction;

type CreateTextFieldControlReducer = (fieldName: string) => Reducer<IFieldControlState, Action>;

const textFieldControlDefaultState: IFieldControlState = {
    value: "",
    errorMessage: "",
};

const textFieldControlReducer: CreateTextFieldControlReducer =
    (fieldName: string): Reducer<IFieldControlState, Action> =>
        (state: IFieldControlState = textFieldControlDefaultState, action: Action): IFieldControlState => {
            switch (action.type) {
                // Case when the server request fails. This should hide the text field and display the
                // error message from the action's payload.
                case `${fieldName.toUpperCase()}_${TextFieldActionType.DISPLAY_TEXT_FIELD_ERROR}`:
                    // Casting because we are using string compare for switch type so TypeScript
                    // cannot infer the correct typing for action.
                    const displayTextFieldErrorAction: IDisplayFieldErrorAction = action as IDisplayFieldErrorAction;

                    return {
                        ...state,
                        errorMessage: displayTextFieldErrorAction.payload.errorMessage,
                    };

                case `${fieldName.toUpperCase()}_${TextFieldActionType.UPDATE_TEXT_FIELD_VALUE}`:
                    // Casting because we are using string compare for switch type so TypeScript
                    // cannot infer the correct typing for action.
                    const updateValueTextFieldAction: IUpdateFieldValueAction = action as IUpdateFieldValueAction;

                    return {
                        ...state,
                        // Never want to set the value to null or undefined. The server should not
                        // return with null properties, but just in case, the client will ensure
                        // that the components get empty string rather then null or undefined.
                        value: updateValueTextFieldAction.payload.newValue
                            ? updateValueTextFieldAction.payload.newValue
                            : "",
                    };

                // This case is used to reset the fields back to the default (display) mode
                // without any error messages.
                case `${fieldName.toUpperCase()}_${TextFieldActionType.RESET_TEXT_FIELD}`:
                    return {
                        ...state,
                        errorMessage: "",
                    };

                default:
                    return state;
            }
        };

export default textFieldControlReducer;

enum RegistrationPersonalAboutYouFormFieldNames {
    // Personal information
    countryOfResidence = "registration_personal_about_you_countryOfResidence",
    firstName = "registration_personal_about_you_firstName",
    lastName = "registration_personal_about_you_lastName",
    dob = "registration_personal_about_you_dob",
    citizenship = "registration_personal_about_you_citizenship",
    socialInsuranceNumber = "registration_personal_about_you_socialInsuranceNumber",
    countryCode = "registration_personal_about_you_countryCode",
    mobileNumber = "registration_personal_about_you_mobileNumber",
    // Current address
    country = "registration_personal_about_you_country",
    addressLine1 = "registration_personal_about_you_addressLine1",
    addressLine2 = "registration_personal_about_you_addressLine2",
    city = "registration_personal_about_you_city",
    state = "registration_personal_about_you_state",
    zipCode = "registration_personal_about_you_zipCode",
    // Other details
    employmentStatus = "registration_personal_about_you_employmentStatus",
    occupation = "registration_personal_about_you_occupation",
    employer = "registration_personal_about_you_employer",
    sourceOfFunds = "registration_personal_about_you_sourceOfFunds",
    otherSourceOfFunds = "registration_personal_about_you_otherSourceOfFunds",
    estimatedNumberOfPaymentsPerMonth = "registration_personal_about_you_estimatedNumberOfPaymentsPerMonth",
    estimatedAverageAmountOfPaymentsPerMonth = "registration_personal_about_you_estimatedAverageAmountOfPaymentsPerMonth",
    // Terms & conditions
    certify = "registration_personal_about_you_certify",
    agree = "registration_personal_about_you_agree",
    read = "registration_personal_about_you_read",
    declare = "registration_personal_about_you_declare",
}

export default RegistrationPersonalAboutYouFormFieldNames;
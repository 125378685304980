import { IFontWeight, IRawStyle, mergeStyles } from "@fluentui/react";

import ConstantUtils from "utils/constantUtils";

type StringOrNumber = string | number;
type ICSSRule = "initial" | "inherit" | "unset";

const xdLetterSpacing = 20;
const thousand = 1000;
const calculateLetterSpacing =
    (fontSize: string) => parseInt(fontSize, ConstantUtils.parseIntRadix) * xdLetterSpacing / thousand;

const textStyles = (
    fontSize: string,
    fontWeight?: IFontWeight | string,
    lineHeight?: ICSSRule | "normal" | StringOrNumber,
): IRawStyle => ({
    fontSize,
    letterSpacing: `${calculateLetterSpacing(fontSize)}px`,
    fontWeight,
    lineHeight,
});

const regularWeight = "normal";
const mediumWeight = 600;
const boldWeight = "bold";

export const regular10 = mergeStyles({
    displayName: "regular10",
    ...textStyles("10px", regularWeight, "25px"),
});

export const regular12 = mergeStyles({
    displayName: "regular12",
    ...textStyles("12px", regularWeight, "18px"),
});

export const regular13 = mergeStyles({
    displayName: "regular13",
    ...textStyles("13px", regularWeight, "20px"),
});

export const regular14 = mergeStyles({
    displayName: "regular14",
    ...textStyles("14px", regularWeight, "21px"),
});

export const medium12 = mergeStyles({
    displayName: "medium12",
    ...textStyles("12px", mediumWeight, "20px"),
});

export const medium13 = mergeStyles({
    displayName: "medium13",
    ...textStyles("13px", mediumWeight, "20px"),
});

export const medium14 = mergeStyles({
    displayName: "medium14",
    ...textStyles("14px", mediumWeight, "28px"),
});

export const regular16 = mergeStyles({
    displayName: "regular16",
    ...textStyles("16px", regularWeight, "24px"),
});

export const medium16 = mergeStyles({
    displayName: "medium16",
    ...textStyles("16px", mediumWeight, "24px"),
});

export const medium18 = mergeStyles({
    displayName: "medium18",
    ...textStyles("18px", mediumWeight, "27px"),
});

export const medium24 = mergeStyles({
    displayName: "medium24",
    ...textStyles("24px", mediumWeight, "36px"),
});

export const bold33 = mergeStyles({
    displayName: "bold33",
    ...textStyles("33px", boldWeight, "48px"),
});
import { Reducer } from "redux";

import DialogBoxActionType from "chrome/dialogBox/duck/actionTypes/dialogBoxActionType";
import ICloseDialogBoxAction from "chrome/dialogBox/duck/actions/interfaces/ICloseDialogBoxAction";
import IDialogBoxState from "chrome/dialogBox/duck/interfaces/IDialogBoxState";
import IOpenDialogBoxAction from "chrome/dialogBox/duck/actions/interfaces/IOpenDialogBoxAction";
import IShowErrorInDialogBoxAction from "chrome/dialogBox/duck/actions/interfaces/IShowErrorInDialogBoxAction";
import IShowSpinnerInDialogBoxAction from "chrome/dialogBox/duck/actions/interfaces/IShowSpinnerInDialogBoxAction";

type Action =
    IOpenDialogBoxAction |
    ICloseDialogBoxAction |
    IShowSpinnerInDialogBoxAction |
    IShowErrorInDialogBoxAction;

const dialogBoxDefaultState: IDialogBoxState = {
    isDialogBoxVisible: false,
    isShowSpinnerInDialogBox: false,
    dialogBoxErrorMessage: "",
};

const dialogBoxReducer: Reducer<IDialogBoxState, Action> =
    (state: IDialogBoxState = dialogBoxDefaultState, action: Action): IDialogBoxState => {
        switch (action.type) {
            case DialogBoxActionType.OPEN_DIALOG_BOX:
                return {
                    ...state,
                    isDialogBoxVisible: true,
                    dialogBoxData: action.payload,
                };

            case DialogBoxActionType.CLOSE_DIALOG_BOX:
                return {
                    ...state,
                    isDialogBoxVisible: false,
                    isShowSpinnerInDialogBox: false,
                };

            case DialogBoxActionType.SHOW_SPINNER_IN_DIALOG_BOX:
                return {
                    ...state,
                    isShowSpinnerInDialogBox: true,
                };

            case DialogBoxActionType.SHOW_ERROR_IN_DIALOG_BOX:
                return {
                    ...state,
                    dialogBoxErrorMessage: action.payload.errorMessage,
                    isShowSpinnerInDialogBox: false,
                };

            default:
                return state;
        }
    };

export default dialogBoxReducer;

import { Reducer } from "redux";

import Action from "content/registration/personal/verification/berbixFlow/duck/actions/types/registrationPersonalVerificationActions";
import ActionType from "content/registration/personal/verification/berbixFlow/duck/actionTypes/registrationPersonalVerificationBerbixFlowActionType";
import State from "content/registration/personal/verification/berbixFlow/duck/interfaces/IRegistrationPersonalVerificationBerbixFlowState";

const defaultState: State = {
    token: "",
    isLoading: false,
    errorMessage: "",
};

const registrationPersonalVerificationBerbixFlowReducer: Reducer<State, Action> =
    (state: State = defaultState, action: Action): State => {
        switch (action.type) {
            case ActionType.REGISTRATION_PERSONAL_VERIFICATION_BERBIX_FLOW_REQUEST_DATA:
                return {
                    ...state,
                    isLoading: true,
                };

            case ActionType.REGISTRATION_PERSONAL_VERIFICATION_BERBIX_FLOW_LOAD_DATA:
                return {
                    ...state,
                    isLoading: false,
                    token: action.payload.token,
                };

            case ActionType.REGISTRATION_PERSONAL_VERIFICATION_BERBIX_FLOW_HIDE_SPINNER:
                return {
                    ...state,
                    isLoading: false,
                };

            case ActionType.REGISTRATION_PERSONAL_VERIFICATION_BERBIX_FLOW_DISPLAY_ERROR:
                return {
                    ...state,
                    isLoading: false,
                    errorMessage: action.payload.errorMessage,
                };

            default:
                return state;
        }
    };

export default registrationPersonalVerificationBerbixFlowReducer;
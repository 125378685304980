import { combineReducers } from "redux";

import ActionType from "content/common/fundingAccount/duck/actionTypes/fundingAccountActionType";
import FundingAccountActions from "content/common/fundingAccount/duck/actions/types/fundingAccountActions";
import fundingAccountTypeSelectionReducer from "content/common/fundingAccount/panelSelectType/duck/fundingAccountSelectTypeReducer";
import fundingAccountVerifyMicroDepositReducer from "content/common/fundingAccount/panelVerifyMicroDeposit/duck/fundingAccountVerifyMicroDepositReducer";
import IFundingAccountContentState from "content/common/fundingAccount/duck/interfaces/IFundingAccountContentState";
import IFundingAccountState from "content/common/fundingAccount/duck/interfaces/IFundingAccountState";

type State = IFundingAccountContentState;
type Action = FundingAccountActions;

const defaultState: State = {
    accounts: [],
    isLoading: true,
    errorMessage: "",
    selectedAccount: ""
};

function fundingAccountContentReducer(
    state: State = defaultState,
    action: Action,
): State {
    switch (action.type) {
        case ActionType.FUNDING_ACCOUNT_REQUEST_DATA:
            return {
                ...state,
                isLoading: true,
            };

        case ActionType.FUNDING_ACCOUNT_LOAD_DATA:
            return {
                ...state,
                isLoading: false,
                accounts: action.payload.accounts,
            };

        case ActionType.FUNDING_ACCOUNT_PREPEND_DATA:
            return {
                ...state,
                accounts: action.payload.accounts && state.accounts
                    ? [...action.payload.accounts, ...state.accounts]
                    : state.accounts,
            };

        case ActionType.FUNDING_ACCOUNT_DISPLAY_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload.errorMessage,
            };

        case ActionType.FUNDING_ACCOUNT_SELECT_ACCOUNT:
            return {
                ...state,
                selectedAccount: action.payload.account
            };

        default:
            return state;
    }
}

const fundingAccountReducer = combineReducers<IFundingAccountState>({
    contentState: fundingAccountContentReducer,
    accountTypePanelState: fundingAccountTypeSelectionReducer,
    verifyMicroDepositPanelState: fundingAccountVerifyMicroDepositReducer
});

export default fundingAccountReducer;
import { Stack } from "@fluentui/react";
import React from "react";

import DialogBox from "chrome/dialogBox/dialogBox";
import HamburgerMenu from "chrome/hamburgerMenu/hamburgerMenu";
import IChromeProps from "chrome/interfaces/IChromeProps";
import Panel from "chrome/panel/panel";
import TopBar from "chrome/topBar/topBar";

export default function Chrome(props: IChromeProps): JSX.Element {
    return (
        <Stack verticalFill>
            <Stack grow horizontal verticalFill>
                <Stack.Item verticalFill>
                    <HamburgerMenu />
                </Stack.Item>
                <Stack.Item grow>
                    <div className="fullHeight">
                        <Stack verticalFill>
                            <TopBar />
                            {props.content}
                        </Stack>
                    </div>
                </Stack.Item>
            </Stack>
            <DialogBox />
            <Panel />
        </Stack>
    );
}
import { BrowserCacheLocation, Configuration, RedirectRequest } from "@azure/msal-browser";

import authConstants from "auth/authConstants";
import policies from "auth/policies";

/**
 * Config object to be passed to MSAL on creation.
 * */
export const msalConfig: Configuration = {
  auth: {
    knownAuthorities: [policies.signUpSignInAuthority],
    clientId: authConstants.clientId,
    authority: policies.signUpSignInAuthority,
    navigateToLoginRequestUrl: false,
    redirectUri: window.location.origin,
  },
  cache: {
    // This configures where your cache will be stored
    cacheLocation: BrowserCacheLocation.SessionStorage,
    // Set this to "true" to save cache in cookies to address trusted zones limitations in IE
    storeAuthStateInCookie: false,
  },
};

/** 
 * Scopes you enter here will be consented once you authenticate.
 */
export const loginRequest: RedirectRequest = {
  scopes: authConstants.scopes,
};

export const forgotPasswordRequest: RedirectRequest = {
  scopes: authConstants.scopes,
  authority: policies.forgotPasswordAuthority,
};

export const changePasswordRequest: RedirectRequest = {
  scopes: authConstants.scopes,
  authority: policies.changePasswordAuthority,
};
